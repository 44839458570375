
import Vue from "vue";
import CardAutocomplete from "../../../../../../components/Content/CardAutocomplete.vue";
import TermList from "./termList.vue";

export default Vue.extend({
	name: "TargetingTabItem",
	props: {
		targeting_key_data: {
			type: Object,
			default: function () {
				return {};
			},
		},
		appLists: {
			type: Array,
			default: function () {
				return [];
			},
		},
		hint: {
			type: String,
			default: "Hint",
		},
		reference: {
			type: String,
			default: "reference",
		},
		placeholder: {
			type: String,
			default: "Placeholder",
		},
		label: {
			type: String,
			default: "Label",
		},
		item_text: {
			type: String,
			default: "value",
		},
		item_value: {
			type: String,
			default: "id",
		},
		predicates: {
			type: Object,
			default: function () {
				return {};
			},
		},
		readonly:{
			type:Boolean,
			default: false
		}
	},
	components: {
		CardAutocomplete,
		TermList,
	},
	data: () => ({}),
	async created() {},
	async mounted() {},
	computed: {},
	methods: {
		removeHandler(value: any) {
			this.$emit("remove-item", value);
		},
		clearHandler() {
			this.$emit("clear");
		},
		focusHandler() {
			this.$emit("focus");
		},
	},

	watch: {},
});

import Vue from "vue";
import { mapGetters } from "vuex";
import CombineBulletGraph from "@/views/Admin/LineItem/Dependencies/GraphicBidWin/CombineBulletGraph/index.vue";
// @ts-ignore
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
// @ts-ignore
import { isEmpty, isNull } from "lodash";

export default Vue.extend({
	name: "MekkoGraphic",
	model: {},
	props: {
		textColor: {
			type: String,
			default: "#5958a5",
		},
		source: {
			type: Array,
			default: function () {
				return [];
			},
		},
		period: {
			type: Array,
			default: function () {
				return [];
			},
		},
		loading: {
			type: Boolean,
			default: false
		},
		infoData: {
			type: Object,
			default: function() {
				return {};
			}
		}
	},
	components: { 
		CombineBulletGraph,
		CardAutocomplete
	},
	data: () => ({
		expanded: false,
		periodData: "1"
	}),
	created() {
		this.$nextTick(async () => {});
	},
	mounted() {},
	computed: {
		...mapGetters("loading", ["isLoading"]),

		getPeriod(){
			return this.period;
		}
	},
	methods: {
		updatePeriod(){
			this.$emit("update-graphic", this.periodData);
		}
	},
	watch: {
		periodData(val, old){
			if(isEmpty(val) || isNull(val)) {
				this.periodData = "1";
			}else{
				this.updatePeriod();
			}
		}
	},
});

import { uniqBy } from "lodash";
import Vue from "vue";
import CardSwitch from "../../../../../../components/Content/CardSwitch.vue";

export default Vue.extend({
	name: "TermList",
	model: {
		prop: "appLists",
		event: "input",
	},
	props: {
		targeting_terms: {
			type: Array,
			default: function () {
				return [];
			},
		},
		appLists: {
			type: Array,
			default: function () {
				return [];
			},
		},
		predicates: {
			type: Object,
			default: function () {
				return {};
			},
		},
		readonly:{
			type:Boolean,
			default: false
		}
	},
	components: { CardSwitch },
	data: () => ({
		dataList: [] as Array<any>,
	}),
	created() {},
	mounted() {},
	computed: {		
		modelData: {
			set(val: any) {
				this.$emit("input", val);
			},
			get() {
				return this.appLists;
			},
		},
	},
	methods: {
		getDisplayNameByValue(targeting_term: any) {
			const app = this.modelData.find(
				(item: any) => item.id == targeting_term?.value
			);

			if (app) return app.value;

			return targeting_term?.value_name || targeting_term?.value;
		},

		removeHandler(value: any) {
			if(this.readonly) return false;
			this.$emit("remove-item", value);
		},

		isIncluded(targeting_predicate_id: Number) {
			return this.predicates.INCLUDED === targeting_predicate_id;
		},
	},
	watch: {
		appLists(val: Array<any>) {
			let newList: Array<any> = this.modelData;
			newList = newList.concat(val);
			newList = uniqBy(newList, "id");
			this.dataList = newList;
		},
	},
});


import Vue from "vue";

export default Vue.extend({
	name: "SkeletonLineItem",
	props: {
		loading: {
			type: Boolean,
			default: false
		}
	},
	components: {},
	data: () => ({}),
	created() {
		this.$nextTick(async () => {});
	},
	mounted() {},
	computed: {
	},
	methods: {},
	watch: {}
});

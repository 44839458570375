
import Vue from "vue";
import TabItem from "./tab_items/TabItem.vue";
import { isEmpty, isUndefined } from "lodash";
import { mapActions } from "vuex";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";

export default Vue.extend({
	name: "Environment",
	props: {
		environment: {
			type: Object,
			default: function() {}
		},
		data_variables: {
			type: Object,
			default: function() {
				return {};
			}
		},
		predicates: {
			type: Object,
			default: function() {
				return {};
			}
		},
		is_edit: {
			type: Boolean,
			default: false
		},
		readonly:{
			type:Boolean,
			default: false
		}
	},
	components: {
		TabItem
	},
	data: () => ({
		tab: "environment"
	}),
	async created() {},
	async mounted() {
		await this.requiredResources();
	},
	computed: {},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		async requiredResources() {
			if (this.is_edit) {
				const keys = Object.keys(this.environment);

				keys.forEach(async key => {
					if (
						!isUndefined(
							this.environment[key]["targeting_terms"]
						) &&
						!isEmpty(this.environment[key]["targeting_terms"])
					) {
						await this.getComboData(key);
					}
				});
			}
		},

		async getComboData(key: string) {
			try {
				let params = {};

				if (isEmpty(this.data_variables[key])) {
					switch (key) {
						case "environment_type":
							params = {
								key: "extra",
								value: "description"
							};

							break;

						case "topframe":
							params = {
								key: "extra",
								value: "description"
							};

							break;

						case "video_api":
							params = {
								key: "extra",
								value: "description"
							};

							break;

						case "rewarded":
							params = {
								key: "extra",
								value: "description"
							};

							break;
					}

					this.setLoadingData(TypeLoading.loading)

					this.$emit("update-data-var", {
						tab: this.tab,
						key: key,
						params: params,
						data: await this.dispatchTargetingKey(key, params)
					});

					await this.setLoadingData();
				}
			} catch (error) {
				console.error("getComboData", { key, error });
				await this.setLoadingData();
			}
		},

		async dispatchTargetingKey(key: String, object?: any) {
			return this.$store.dispatch("targeting/getTargetingKey", {
				key: key,
				object: object
			});
		},

		clearHandler(key: any) {
			this.$emit("clear-app-site", { tab: this.tab, key: key });
		},

		removeHandler(key: any, value: any, is_unique: Boolean = false) {
			this.$emit(is_unique ? "remove-item-unique" : "remove-item", {
				tab: this.tab,
				key: key,
				value: value,
				is_unique: is_unique
			});
		},

		async updateWatchByKey(
			key: String,
			val: Array<any>,
			old: Array<any>,
			is_unique: Boolean = false
		) {
			if (val.length > old.length) {
				const item = val.filter(function(o: any) {
					return !old.includes(o);
				});
				this.$emit(is_unique ? "add-item-unique" : "add-item", {
					tab: this.tab,
					key: key,
					value: item[0]
				});
			}
			if (val.length < old.length) {
				const item = old.filter(function(o: any) {
					return !val.includes(o);
				});
				this.$emit(is_unique ? "remove-item-unique" : "remove-item", {
					tab: this.tab,
					key: key,
					value: item[0]
				});
			}
		}
	},

	watch: {
		async "environment.environment_type.value"(
			val: Array<any>,
			old: Array<any>
		) {
			await this.updateWatchByKey("environment_type", val, old, true);
		},
		async "environment.topframe.value"(val: Array<any>, old: Array<any>) {
			await this.updateWatchByKey("topframe", val, old, true);
		},
		async "environment.video_api.value"(val: Array<any>, old: Array<any>) {
			await this.updateWatchByKey("video_api", val, old, true);
		},
		async "environment.rewarded.value"(val: Array<any>, old: Array<any>) {
			await this.updateWatchByKey("rewarded", val, old, true);
		}
	}
});

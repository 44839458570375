import CreateTabs from "@/views/Admin/Commons/Tabs.vue";
import Overview from "@/views/Admin/LineItem/Dependencies/Forms/Overview.vue";
import NavTargeting from "@/views/Admin/LineItem/Dependencies/Targetings/tab_contents/targetingNav.vue";
import SummaryTargeting from "@/views/Admin/LineItem/Dependencies/Targetings/tab_contents/targetingSummary.vue";
import Vue from "vue";
import { CampaingFilters, CampaingOptions } from "@/interfaces/campaign";

import ParamService from "@/services/params-service";

import {
	TargetingDataCreate,
	TargetingDataUpdate,
	Term,
} from "@/interfaces/targeting";

import {
	initLineItem,
	initDataVariables,
	getTargetingIDByValue,
	getLineItemResources,
} from "@/utils/initData";
import Alertize from "@/components/Alertize.vue";
import {
	find,
	isArray,
	isEmpty,
	isNull,
	isString,
	isUndefined,
	trim,
	filter,
	first,
	debounce
} from "lodash";
import { LineItemDataCreate, LineItemDataUpdate, GraphicBidWin } from "@/interfaces/line_item";
import CardTextField from "@/components/Content/CardTextField.vue";
import AppSite from "@/views/Admin/LineItem/Dependencies/Targetings/appSite.vue";
import Content from "@/views/Admin/LineItem/Dependencies/Targetings/content.vue";
import Environment from "@/views/Admin/LineItem/Dependencies/Targetings/environment.vue";
import Geo from "@/views/Admin/LineItem/Dependencies/Targetings/geo.vue";
import Platform from "@/views/Admin/LineItem/Dependencies/Targetings/platform.vue";
import Exchange from "@/views/Admin/LineItem/Dependencies/Targetings/exchange.vue";
import User from "@/views/Admin/LineItem/Dependencies/Targetings/user.vue";
import Time from "@/views/Admin/LineItem/Dependencies/Targetings/time.vue";
import { prepareTargetingDataCreate } from "@/utils/resolveObjectArray";
import SkeletonLineItem from "@/views/Admin/LineItem/Dependencies/Forms/Skeleton.vue";
import { SortingOption } from "@/interfaces/paginated";
import CardActions from "@/components/Content/CardActions.vue";
import EventsContent from "@/views/Admin/LineItem/Dependencies/AssociationsEvents/AssociatedEvents.vue";

import NavModifiersModels from "@/views/Admin/Campaigns/Dependencies/TabNavigation.vue";
import BidModelContent from "@/views/Admin/Campaigns/Dependencies/Contents/bidModel.vue";
import BidModifierContent from "@/views/Admin/Campaigns/Dependencies/Contents/bidModifier.vue";
import DeliveryModelContent from "@/views/Admin/Campaigns/Dependencies/Contents/deliveryModel.vue";
import DeliveryModifierContent from "@/views/Admin/Campaigns/Dependencies/Contents/deliveryModifier.vue";
import { Notification, MessageTypes } from "@/interfaces/proccess";

import { mapActions, mapGetters } from "vuex";
import { parseToLower } from "@/utils/filter-global";
import { InitTargeting } from "@/models/tabs/Targeting";

import AssociationForm from "@/views/Admin/LineItem/Dependencies/Creatives/AssociationForm.vue";

import { 
	getPermisionTargeting, 
	getPermisionLineItem, 
	getPermisionModifiers,
	getPermisionEvent
} from "@/utils/permissionResolve.ts";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";

const DEFAULT_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
// Configs Optimization Strategy
// const OPTIMIZATION_BY_LINE = "By Line";
const OPTIMIZATION_BY_CAMPAIGN = "By Campaign";
const OPTIMIZATION_BY_LINE = "By Line";
// Configs Bid Strategy
const BID_STRATEGY_FIXED = "Fix";
const BID_STRATEGY_AUTOMATED = "Automated";

// Configs Budget Type
const BUDGET_TYPE_IMPRESSIONS = "Impressions";
const BUDGET_TYPE_SPEND = "Spend";

// Configs to Optimization Strategy
const OPTIMIZED_CPM = "Optimized CPM";
const OPTIMIZED_CPC = "Optimized CPC";
const OPTIMIZED_VCR = "Optimized VCR";

// Configs Line Item Type
const LINE_ITEM_TYPE_VIDEO = "Video";

// Configs Line Pacing
const LINE_PACING_DAILY = "Daily";
//const LINE_PACING_ASAP = "ASAP";
const LINE_PACING_LIFETIME = "Lifetime";

// Configs Targeting Predicates
const EXCLUDED = "None";
const INCLUDED = "All";
const REQUIRE = "Require";

const RANDOM = "RANDOM";

const BANNER_OPTIONS: Array<string> = [
	parseToLower(OPTIMIZED_CPM),
	parseToLower(OPTIMIZED_CPC),
];

const VIDEO_OPTIONS: Array<string> = [
	parseToLower(OPTIMIZED_VCR),
	parseToLower(OPTIMIZED_CPM),
];

export default Vue.extend({
	name: "OverviewLineItem",
	props: {},
	components: {
		CreateTabs,
		Overview,
		Alertize,
		NavTargeting,
		CardTextField,
		AppSite,
		Content,
		Environment,
		Geo,
		Exchange,
		User,
		Time,
		Platform,
		SkeletonLineItem,
		CardActions,
		NavModifiersModels,
		BidModelContent,
		BidModifierContent,
		DeliveryModelContent,
		DeliveryModifierContent,
		AssociationForm,
		SummaryTargeting,
		EventsContent,
	},
	data: () => ({
		title: "OverviewLineItem",
		currentTab: 0,
		currentTabTargeting: 8,
		items: [],

		filters: {},
		options: { sort: "id", order: "desc" } as SortingOption,

		//Aux App Site
		data_variables: initDataVariables(),

		targeting_predicates: [],

		// Line Item Data
		//line_item: initHardCoreLineItem(),
		line_item: {},

		// Targeting Data
		targeting: new InitTargeting(),

		targeting_expressions: null,

		targeting_summary: [],

		resources: getLineItemResources(),

		updateCountBidModifier: true,

		// Tab Modifiers and Models
		currentTabModifiers: 0,

		loadTargeting: true,

		fieldLabel: {
			daily_budget: "Daily Budget",
			daily_budget_suggested: "Daily Budget Suggested",
		},

		bid_win:{
			id: undefined,
			start_date: undefined,
			end_date: undefined
		} as GraphicBidWin,

		dataGraphic:{
			data: null,
			period:[],
			loadingGraph: false
		},

		updateCPMBid: true,

		flag_clac_cpm_bid: false,

		allows_events: false,
	}),
	async created() {
		// set campaign defaults
		await this.setSelectedCampaign({
			advertiser: { id: null, name: null },
			optimization_strategy_id: null,
		});
	},
	async mounted() {
		this.$nextTick(async () => {
			try {
				this.clearError();

				await this.setLoadingData(TypeLoading.loading);

				await this.loadInitResources();

				await this.loadEditResources();

				await this.setIndexTab();

				await this.setLoadingData();
				this.loadGrafico();
			} catch (error) {
				console.error("mounted", { error });
				await this.setLoadingData();
			}
		});
	},
	computed: {
		...mapGetters("profile", ["isRolReport", "getAbility"]),

		isReadOnly(){
			return this.isRolReport;
		},

		getID(): Number {
			return Number(this.$route.params.id);
		},
		hasID() {
			return !!this.$route.params.id;
		},

		hasCampaignID() {
			return this.$route.query.campaign_id > 0;
		},

		getCampaignID(): Number {
			return Number(this.$route.query.campaign_id);
		},

		hasTabIndex() {
			return this.$route.query.section > 0;
		},

		hasEventPermissions() {
			return this.allows_events;
		},

		getTabIndex(): Number {
			return Number(this.$route.query.section);
		},

		isLoading() {
			return this.$store.state.proccess.loading;
		},
		getLineItem() {
			return this.line_item;
		},

		getCreativeAssociations(): any[] {
			return this.getLineItem?.creative_associations || [];
		},

		getTargetingExpressions() {
			return this.$store.state.targeting.targeting_expressions;
		},
		getTargetingSummary() {
			return this.$store.state.targeting.targeting_summary;
		},
		getFrecuencyCapType(){
			return this.resources.types_frecuency_caps;
		},
		hasTargetingExpressionsID() {
			return (
				this.hasData(this.getTargetingExpressions) &&
				this.hasData(this.getTargetingExpressions?.id)
			);
		},
		getTargetingExpressionsID() {
			return this.getTargetingExpressions?.id;
		},
		getItems() {
			return this.updateItems;
		},
		updateItems() {
			this.items = [
				{
					key: 0,
					tab: this.$t('lineItem.tabs.overview'),
					disabled: false,
				},
				{
					key: 1,
					tab: this.$t('lineItem.tabs.targeting'),
					disabled: this.isAccessTargeting,
				},
				{
					key: 2,
					tab: this.$t('lineItem.tabs.modifier'),
					disabled: this.isAccessModifers
				},
				{
					key: 3,
					tab: this.$t('lineItem.tabs.creative'),
					//disabled: false,// TODO comentar esto
					disabled: this.isAccessCreatives,
				},
				{
					key: 4,
					tab: this.$t('lineItem.tabs.event'),
					//disabled: false,// TODO comentar esto
					disabled: this.isAccessEvent
						
				},
			];
			return this.items;
		},

		isAccessTargeting(){
			if(this.canTargeting && (this.canCreateTargeting || this.canUpdateTargeting)){
				return !this.isCreatedLineItem;
			}else{
				return true;
			}
		},

		isAccessEvent(){
			if(this.canEvent){
				return !this.isCreatedLineItem || !this.hasEventPermissions
			}else{
				return true;
			}
		},

		isAccessModifers(){
			if(this.canCreateModifier || this.canUpdateModifier){
				return !this.isCreatedLineItem &&
						!this.hasTargetingExpressionsID;
			}else{
				return true;
			}
		},

		isAccessCreatives(){
			if(this.canAccessCreative && 
				(this.canAccessCreativeCreate || this.canAccessCreativeUpdate)){
					return !this.isCreatedLineItem &&
						!this.hasTargetingExpressionsID;
			}else{
				return true;
			}
		},

		/**
		 * End Resources
		 */

		getErrors() {
			return this.$store.state.proccess.errors;
		},
		hasErrors() {
			return Object.keys(this.$store.state.proccess.errors).length > 0;
		},
		getPrepareTargetingDataCreate() {
			return prepareTargetingDataCreate(this.targeting);
		},
		isCreatedLineItem() {
			return this.hasData(this.line_item.id);
		},
		hasAssociatedCreatives() {
			return (
				this.hasData(this.line_item.creative_associations) &&
				!isEmpty(this.line_item.creative_associations)
			);
		},
		getAssociatedCreatives(): Array<any> {
			return this.line_item.creative_associations;
		},
		hasTargetingExpressions() {
			return (
				this.hasData(this.getTargetingExpressions) &&
				!isEmpty(this.getTargetingExpressions)
			);
		},

		/**
		 *
		 */

		getSelectedCampaign() {
			return this.resources.selected_campaign;
		},

		getPredicateIds() {
			return {
				INCLUDED: this.isIncluded,
				EXCLUDED: this.isExcluded,
				REQUIRED: this.isRequired,
			};
		},

		isExcluded() {
			const result = find(this.targeting_predicates, {
				value: EXCLUDED,
			});

			return parseInt(result?.id);
		},

		isIncluded() {
			const result = find(this.targeting_predicates, {
				value: INCLUDED,
			});

			return parseInt(result?.id);
		},

		isRequired() {
			const result = find(this.targeting_predicates, {
				value: REQUIRE,
			});

			return parseInt(result?.id);
		},
		isCampaignSelectedPMP(){
			return this.resources?.selected_campaign?.campaign_pmp;
		},

		canTargeting(){
			return this.getAbility.can(this.getPermissionTarget.actions.index, 
				this.getPermissionTarget.subject);
		},

		canCreateTargeting(){
			return this.getAbility.can(this.getPermissionTarget.actions.create, 
				this.getPermissionTarget.subject);
		},

		canUpdateTargeting(){
			return this.getAbility.can(this.getPermissionTarget.actions.update, 
				this.getPermissionTarget.subject);
		},

		canEvent(){
			return this.getAbility.can(this.getPermissionLine.actions.event_asso, 
				this.getPermissionLine.subject);
		},

		canCreateModifier(){
			return this.getAbility.can(this.getPermissionModifier.subComponent.bid_modifier
				.create_bid, this.getPermissionModifier.subject) ||
				this.getAbility.can(this.getPermissionModifier.subComponent.delivery_modifier
				.create_delivery, this.getPermissionModifier.subject);
		},

		canUpdateModifier(){
			return this.getAbility.can(this.getPermissionModifier.subComponent.bid_modifier
				.update_bid, this.getPermissionModifier.subject) ||
				this.getAbility.can(this.getPermissionModifier.subComponent.delivery_modifier
				.update_delivery, this.getPermissionModifier.subject);
		},

		canAccessCreative(){
			return this.getAbility.can(this.getPermissionLine.complement.actions.index_assoc, 
				this.getPermissionLine.complement.subject);
		},

		canAccessCreativeCreate(){
			return this.getAbility.can(this.getPermissionLine.complement.actions.create_assoc, 
				this.getPermissionLine.complement.subject);
		},

		canAccessCreativeUpdate(){
			return this.getAbility.can(this.getPermissionLine.complement.actions.update_assoc, 
				this.getPermissionLine.complement.subject);
		},

		canAccessActiveLineItem(){
			return this.getAbility.can(this.getPermissionLine.actions.active, 
				this.getPermissionLine.complement.subject);
		},

		getPermissionTarget(){
			return getPermisionTargeting();
		},

		getPermissionLine(){
			return getPermisionLineItem();
		},

		getPermissionModifier(){
			return getPermisionModifiers();
		},

		getPermissionEvents(){
			return getPermisionEvent();
		}
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("proccess", ["setLoadingField"]),
		...mapActions("line_item", ["dataGraphicBidWin", "periodGraphicBidWin", "setLine"]),

		clearError() {
			this.$store.state.proccess.errors = {};
		},

		async setIndexTab() {
			const tabIndex: any =
				!isUndefined(this.$route.query?.tabIndex) &&
				Number(this.$route.query?.tabIndex) > -1
					? Number(this.$route.query?.tabIndex)
					: undefined;

			if (tabIndex) {
				this.updateSelectedTabIndex(tabIndex);
			} else if (this.hasTabIndex) {
				this.updateSelectedTabIndex(this.getTabIndex);
			} else {
				this.updateSelectedTabIndex();
			}
		},

		handleCancelModifier() {
			this.$router.push({ name: "LineItemList" });
		},

		async updateBudgetDisplay() {
			const result = find(this.resources.budget_types, {
				id: this.line_item.budget_type_id,
			});

			if (result?.value == BUDGET_TYPE_SPEND) {
				this.resources.budget_currency =
					this.resources.selected_campaign?.currency_key;
			} else {
				this.resources.budget_currency = undefined;
			}
			this.resources.budget_display = `Total ${result?.value || ""}`;
		},

		async updateStrategies() {
			const strategies = this.resources.strategies;

			const isVideo: Boolean = await this.isLineItemType(
				LINE_ITEM_TYPE_VIDEO
			);

			this.resources.strategies_filtered = strategies;

			if (!this.line_item?.line_item_type_id) {
				this.setField("strategy_id", {
					show: true,
					required: true,
					disabled: false,
					value: undefined,
				});
				this.setField("bid_strategy_id", {
					show: true,
					required: true,
					disabled: false,
					value: undefined,
				});
				return;
			}

			this.resources.strategies_filtered = this.filterStrategies(
				strategies,
				isVideo
			);
		},

		async updateBidStrategies() {
			// TODO refactorizar para una sola llamada

			//this.resources.bid_strategies = await this.dispatchBidStrategies();

			if (
				!this.line_item?.strategy_id ||
				!this.line_item?.line_item_type_id
			) {
				this.setField("bid_strategy_id", {
					show: true,
					required: true,
					disabled: false,
					value: undefined,
				});
				return;
			}

			const isOptimizedCPM: Boolean = await this.isStrategy(
				OPTIMIZED_CPM
			);

			const isOptimizedVCR: Boolean = await this.isStrategy(
				OPTIMIZED_VCR
			);

			if (!isOptimizedCPM) {
				this.resources.bid_strategies =
					this.resources.bid_strategies.filter(
						(bs: any) => bs.value !== BID_STRATEGY_FIXED
					);
			}

			// bid_strategy_id
			this.setField("bid_strategy_id", {
				show: true,
				required: true,
				disabled: isOptimizedVCR && !!this.line_item?.strategy_id,
				value: isOptimizedVCR
					? this.findByValue(
							this.resources.bid_strategies,
							BID_STRATEGY_AUTOMATED
					  )?.id
					: undefined,
			});
		},

		async updateMaxCpmBid() {
			const isOptimizedCPC: Boolean = await this.isStrategy(
				OPTIMIZED_CPC
			);

			const isAutomated: Boolean = await this.isBidStrategy(
				BID_STRATEGY_AUTOMATED
			);

			// max_cpm_bid
			this.setField("max_cpm_bid", {
				show: isOptimizedCPC && isAutomated,
				required: isOptimizedCPC && isAutomated,
				disabled: !(isOptimizedCPC && isAutomated),
			});
		},

		findByValue(data: Array<any>, value: string) {
			return first(
				filter(data, function (o) {
					return (
						String(o.value).toLowerCase() === value.toLowerCase()
					);
				})
			);
		},

		filterStrategies(data: Array<any>, isVideoOption: Boolean) {
			return filter(data, function (o) {
				return isVideoOption
					? VIDEO_OPTIONS.includes(parseToLower(o.value))
					: BANNER_OPTIONS.includes(parseToLower(o.value));
			});
		},

		async getLineItemByID(id: number) {
			return await this.$store.dispatch("line_item/show", id);
		},

		async refreshData() {
			const line_item = await this.getLineItemByID(this.getLineItem?.id);
			this.line_item = line_item;
			this.allows_events = line_item.allows_events;
		},

		async fetchTargetingExpressions() {
			return await this.$store.dispatch(
				"targeting/all",
				await ParamService.getParams(
					{},
					this.filters,
					this.options,
					true
				)
			);
		},

		/**
		 * Aplica cambios en los datos obtenidos desde la campaña
		 */
		async applyCampaignSelectedToLineItem(from_campaign: Boolean = false) {
			const campaign = this.resources.selected_campaign;
			const optimization_strategy = campaign?.optimization_strategy;
			const isByCampaign: Boolean =
				optimization_strategy?.description === OPTIMIZATION_BY_CAMPAIGN;
			const isByLine: Boolean =
				optimization_strategy?.description === OPTIMIZATION_BY_LINE;
			const isAutomated: Boolean = await this.isBidStrategy(
				BID_STRATEGY_AUTOMATED
			);
			const isFix: Boolean = await this.isBidStrategy(BID_STRATEGY_FIXED);

			const isPacingDaily: Boolean = await this.isLinePacing(
				LINE_PACING_DAILY
			);

			// budget
			this.setField("budget", {
				show: true,
				required: true,
				disabled: this.getSelectedCampaign && isByCampaign,
			});

			// line_pacing_id
			this.setField("line_pacing_id", {
				show: isByLine,
				required: true,
				disabled: !isByLine,
			});

			// daily_budget
			this.setField("daily_budget", {
				show: isPacingDaily,
				required: isPacingDaily,
				disabled: !isPacingDaily,
				value: this.line_item?.daily_budget || campaign?.daily_budget,
			});

			// daily_budget_suggested
			this.setField("daily_budget_suggested", {
				show: isPacingDaily,
				required: false,
				disabled: true,
			});

			if (from_campaign) {
				// budget
				this.setField("budget", {
					show: true,
					required: true,
					disabled: this.getSelectedCampaign && isByCampaign,
					value: isByCampaign
						? campaign?.budget
						: this.line_item?.budget,
				});

				// start_date
				this.setField("start_date", {
					show: true,
					required: true,
					disabled: this.getSelectedCampaign && isByCampaign,
					//value: campaign?.start_date || undefined,
					value:
						(this.hasID
							? new Date(this.line_item.start_date)
							: new Date(campaign?.start_date)) ?? undefined,
				});

				// end_date
				this.setField("end_date", {
					show: true,
					required: true,
					disabled: false,
					//value: campaign?.end_date || undefined,
					value:
						(this.hasID
							? new Date(this.line_item.end_date)
							: new Date(campaign?.end_date)) ?? undefined,
				});

				// line_pacing_id
				this.setField("line_pacing_id", {
					show: isByLine,
					required: true,
					disabled: !isByLine,
					value: isByLine
						? this.line_item?.line_pacing_id ??
						  campaign?.campaign_pacing_id
						: undefined,
				});
				// advertiser_id
				this.resources.advertisers = [
					{
						id: campaign?.advertiser.id,
						value: campaign?.advertiser.name,
					},
				];
				this.setField("advertiser_id", {
					show: true,
					required: true,
					disabled: false,
					value: campaign?.advertiser_id,
				});

				// strategy_id
				this.setField("strategy_id", {
					show: true,
					required: true,
					disabled: false,
					value: this.line_ite?.strategy_id || campaign?.strategy_id,
				});

				// budget_type_id
				this.setField("budget_type_id", {
					show: true,
					required: true,
					disabled: true,
					value: campaign?.budget_type_id,
				});
			}

			// if (isAutomated) {
			// 	this.resources.strategies = await this.dispatchStrategies();
			// }

			//const isSpend: Boolean = await this.isBudgetType(BUDGET_TYPE_SPEND);
			const isImpression: Boolean = await this.isBudgetType(
				BUDGET_TYPE_IMPRESSIONS
			);

			this.setLabel(
				"daily_budget",
				`Daily ${isImpression ? "Impressions" : "Budget"}`
			);

			this.setLabel(
				"daily_budget_suggested",
				`Daily ${isImpression ? "Impressions" : "Budget"} Suggested`
			);

			// fix_cpm
			this.setField("fix_cpm", {
				show: isFix,
				required: isFix,
				disabled: !isFix,
			});

			const isOptimizedCPC: Boolean = await this.isStrategy(
				OPTIMIZED_CPC
			);

			const isOptimizedCPM: Boolean = await this.isStrategy(
				OPTIMIZED_CPM
			);

			const isOptimizedVCR: Boolean = await this.isStrategy(
				OPTIMIZED_VCR
			);

			// cpm_bid
			this.setField("cpm_bid", {
				show: (isOptimizedVCR || isOptimizedCPM) && isAutomated,
				required: (isOptimizedVCR || isOptimizedCPM) && isAutomated,
				disabled: !((isOptimizedVCR || isOptimizedCPM) && isAutomated),
			});

			// expected_ctr
			this.setField("expected_ctr", {
				show: isOptimizedCPC && isAutomated,
				required: isOptimizedCPC && isAutomated,
				disabled: false,
			});

			// max_cpm_bid
			this.setField("max_cpm_bid", {
				show: isOptimizedCPC && isAutomated,
				required: isOptimizedCPC && isAutomated,
				disabled: !(isOptimizedCPC && isAutomated),
			});

			// bid_strategy_id
			// this.setField("bid_strategy_id", {
			// 	show: true,
			// 	required: true,
			// 	disabled: false
			// });

			// bid_shading_id
			// this.setField("bid_shading_id", {
			// 	show: isOptimizedVCR || isOptimizedCPM && !isFix,
			// 	required: isOptimizedVCR || isOptimizedCPM,
			// 	disabled: !(isOptimizedVCR || isOptimizedCPM),
			// 	value: undefined
			// });

			// target_ecpc
			this.setField("target_ecpc", {
				show: isOptimizedCPC && isAutomated,
				required: isOptimizedCPC && isAutomated,
				disabled: !(isOptimizedCPC && isAutomated),
			});

			// target_vcr
			this.setField("target_vcr", {
				show: isOptimizedVCR && isAutomated,
				required: isOptimizedVCR && isAutomated,
				disabled: !(isOptimizedVCR && isAutomated),
			});

			// update strategies filtered
			await this.updateStrategies();

			//await this.updateBidStrategies();

			await this.updateBudgetDisplay();
		},

		async isOptimizationStrategy(key: any): Promise<Boolean> {
			return (
				find(this.resources.optimization_strategies, { value: key })
					?.id ===
				this.resources.selected_campaign?.optimization_strategy_id
			);
		},

		isCampaignOptimizationByLine() {
			return (
				this.resources.selected_campaign?.optimization_strategy?.description !==
				OPTIMIZATION_BY_CAMPAIGN
			);
		},

		async isBidStrategy(key: string): Promise<Boolean> {
			/*
			if (isEmpty(this.resources.bid_strategies)) {
				this.resources.bid_strategies =
					await this.dispatchBidStrategies();
			}*/

			const bid_strategy = find(this.resources.bid_strategies, {
				value: key,
			});

			return bid_strategy?.id === this.line_item.bid_strategy_id;
		},

		// async isBidShadding(key: string): Promise<Boolean> {
		// 	if (isEmpty(this.resources.bidding_shadings)) {
		// 		this.resources.bidding_shadings = await this.dispatchBiddingShadings();
		// 	}

		// 	const bid_shading = find(this.resources.bidding_shadings, {
		// 		value: key
		// 	});

		// 	return bid_shading?.id === this.line_item.bid_shading_id;
		// },

		async isBudgetType(key: string): Promise<Boolean> {
			const budget_type = find(this.resources.budget_types, {
				value: key,
			});

			return budget_type?.id === this.line_item.budget_type_id;
		},

		async isLineItemType(key: string): Promise<Boolean> {
			const line_item_type = find(this.resources.line_item_types, {
				value: key,
			});
			return line_item_type?.id === this.line_item.line_item_type_id;
		},

		async isStrategy(key: string): Promise<Boolean> {
			const strategy = find(this.resources.strategies, {
				value: key,
			});
			return strategy?.id === this.line_item.strategy_id;
		},

		async isLinePacing(key: string): Promise<Boolean> {
			const line_pacing = find(this.resources.line_pacings, {
				value: key,
			});
			return line_pacing?.id === this.line_item.line_pacing_id;
		},

		async getKeyBidStrategy(key: string): Promise<Number> {
			const strategy = find(this.resources.bid_strategies, {
				value: key,
			});
			return strategy?.id;
		},

		async getRamdomWeight(): Promise<Boolean> {
			const creative_weighting_method = find(
				this.resources.creative_weighting_methods,
				{
					value: RANDOM,
				}
			);
			return creative_weighting_method?.id;
		},

		async setFilters(lineItemId: any) {
			this.filters = { line_item_id: lineItemId };
		},

		async setLineItem(lineItem: any) {
			this.line_item = lineItem;

			// Allow to add events or not after create the item
			this.allows_events = lineItem.allows_events;

			if (this.hasID) {
				await this.updateCtrVcr();
			}
		},

		async setSelectedCampaign(campaign: {
			advertiser: { id: any; name: any };
		}) {
			this.resources.selected_campaign = campaign;
			this.resources.advertisers = [
				{
					id: campaign?.advertiser.id,
					value: campaign?.advertiser.name,
				},
			];
		},

		hasData(attr: any) {
			return !isUndefined(attr) && !isNull(attr);
		},

		isValidDates() {
			const startDate = this.moment(this.line_item.start_date);
			const endDate = this.moment(this.line_item.end_date);
			return startDate.isValid() && endDate.isValid();
		},

		getCalculateDuration() {
			if (!this.isValidDates()) return undefined;
			const startDate = this.moment(this.line_item.start_date);
			const endDate = this.moment(this.line_item.end_date);
			let days = this.calculateDuration(startDate, endDate);

			if (days < 0) {
				this.line_item.line_duration = undefined;
				return undefined;
			}

			this.line_item.line_duration = days;

			return days;
		},
		calculateDuration(start: any, end: any) {
			if (!(start.isValid() && end.isValid())) {
				return -1;
			}
			//const diff = end.diff(start, "days");
			const duration = this.moment.duration(end.diff(start));
			return Math.ceil(duration.asDays());
		},

		async fetchResource(params: { resource: string }) {
			this.setLoadingField(true);

			switch (params.resource) {
				case "campaign_id":
					if (!this.hasID) {
						if (isEmpty(this.resources.campaigns)) {
							this.resources.campaigns =
								await this.dispatchCampaigns();
						}
					}
					break;

				case "line_item_type_id":
					this.resources.line_item_types = await this.dispatchTypes();
					//await this.updateBidStrategies();
					break;

				case "strategy_id":
					//this.resources.strategies = await this.dispatchStrategies();

					// update strategies filtered
					await this.updateStrategies();

					//await this.updateBidStrategies();

					break;

				// case "bid_shading_id":
				// 	if (isEmpty(this.resources.bidding_shadings)) {
				// 		this.resources.bidding_shadings = await this.dispatchBiddingShadings();
				// 	}
				// 	break;

				case "creative_method_id":
					if (isEmpty(this.resources.creative_weighting_methods)) {
						this.resources.creative_weighting_methods =
							await this.dispatchCreativeWeightingMethods();
					}
					break;

				case "line_pacing_id":
					if (isEmpty(this.resources.line_pacings)) {
						this.resources.line_pacings =
							await this.dispatchLinesPacing();
					}
					break;

				case "unit_time_id":
					if (isEmpty(this.resources.unit_times)) {
						this.resources.unit_times =
							await this.dispatchUnitTimes();
					}
					break;

				case "type_frecuency_cap":
					if (isEmpty(this.resources.types_frecuency_caps)) {
						this.resources.types_frecuency_caps =
							await this.dispatchTypesFrecuencyCaps();
					}
					break;
			}
			this.setLoadingField(false);
		},

		async handleChange(params: { key: string | number; value: any }) {
			if (this.isLoading) return;
			this.setLoadingField(true);

			if (!isUndefined(params.value)) {
				this.line_item[params.key] = params.value;
			}

			switch (params.key) {
				case "line_duration":
					this.line_item[params.key] = this.getCalculateDuration();
					break;

				case "line_item_type_id":
					await this.updateStrategies();
					//await this.updateBidStrategies();
					break;

				case "strategy_id":
					//await this.updateBidStrategies();
					await this.updateMaxCpmBid();

					const isOptimizedCPC: Boolean = await this.isStrategy(
						OPTIMIZED_CPC
					);

					const isOptimizedVCR: Boolean = await this.isStrategy(
						OPTIMIZED_VCR
					);

					if(isOptimizedCPC || isOptimizedVCR)
					{
						const keyBidStrategy: Number = await this.getKeyBidStrategy(BID_STRATEGY_AUTOMATED);
						this.line_item['bid_strategy_id'] = keyBidStrategy;
					}
					break;

				case "bid_strategy_id":
					const isFix: Boolean = await this.isBidStrategy(
						BID_STRATEGY_FIXED
					);

					// fix_cpm
					this.setField("fix_cpm", {
						show: isFix,
						required: isFix,
						disabled: !isFix,
					});

					await this.updateMaxCpmBid();

					break;

				case "campaign_id":
					this.resources.selected_campaign =
						await this.dispatchGetCampaignById(
							Number(params.value)
						);
					this.resources.optimization_strategies =
						await this.dispatchOptimizationStrategies();
					this.updateFieldsForCampaignPMP();
					break;

				case "budget_type_id":
					break;

				case "line_pacing_id":
					this.line_item.line_duration = this.getCalculateDuration();
					break;

				case "daily_budget":
					this.line_item.line_duration = this.getCalculateDuration();
					break;

				case "target_ecpc":
					//await this.calcMaxCpmBid();
					await this.calcExpectedCTR()
					break;

				case "expected_ctr":
					//await this.calcMaxCpmBid();
					break;

				case "max_cpm_bid":
					await this.calcExpectedCTR();
					/*
					this.flag_clac_cpm_bid = true;
					await this.calcExpectedCTR()
					.then((response) => {
						this.flag_clac_cpm_bid = false;
					});*/
					break;
			}

			await this.applyCampaignSelectedToLineItem(
				params.key === "campaign_id"
			);

			this.setLoadingField(false);
		},

		async getStrategyByValue(){
			return this.resources.strategies
						.find(e => String(e.value).toUpperCase() == String(OPTIMIZED_CPM).toUpperCase());
		},

		async getBidStrategyByValue(){
			return this.resources.bid_strategies
						.find(e => String(e.value).toUpperCase() == String(BID_STRATEGY_FIXED).toUpperCase());
		},

		async getLinePacingByValue(){
			return this.resources.line_pacings
						.find(e => String(e.value).toUpperCase() == String(LINE_PACING_LIFETIME).toUpperCase());
		},

		async calcMaxCpmBid() {
			if (!this.line_item?.expected_ctr || !this.line_item?.target_ecpc) {
				this.line_item["max_cpm_bid"] = null;
				return;
			}

			const maxCpmBid =
				(this.line_item?.expected_ctr / 100) *
				(this.line_item?.target_ecpc * 1000);

			this.line_item["max_cpm_bid"] = maxCpmBid;
		},

		async calcExpectedCTR() {
			if (!this.line_item?.target_ecpc || !this.line_item?.max_cpm_bid) {
				//this.line_item["expected_ctr"] = null;
				return;
			}
			let valueCTR = this.line_item["expected_ctr"];
			const valExpectedCTR = ((this.line_item?.max_cpm_bid / (1000 * this.line_item?.target_ecpc))) * 100;
			this.line_item["expected_ctr"] = valExpectedCTR.toFixed(2);

			/*
			if (!this.line_item?.expected_ctr || !this.line_item?.max_cpm_bid) {
				//this.line_item["expected_ctr"] = null;
				return;
			}
			let valueCTR = this.line_item["expected_ctr"];
			const valExpectedCTR = ((this.line_item?.max_cpm_bid / 1000) / valueCTR) * 100;
			this.line_item["expected_ctr"] = valExpectedCTR;*/
		},

		async loadDeliveryBidding() {
			const isAutomated: Boolean = await this.isBidStrategy(
				BID_STRATEGY_AUTOMATED
			);

			const isOptimizedCPC: Boolean = await this.isStrategy(
				OPTIMIZED_CPC
			);

			const isOptimizedCPM: Boolean = await this.isStrategy(
				OPTIMIZED_CPM
			);

			const isOptimizedVCR: Boolean = await this.isStrategy(
				OPTIMIZED_VCR
			);

			const isFix: Boolean = await this.isBidStrategy(BID_STRATEGY_FIXED);

			// cpm_bid
			this.setField("cpm_bid", {
				show: (isOptimizedVCR || isOptimizedCPM) && isAutomated,
				required: (isOptimizedVCR || isOptimizedCPM) && isAutomated,
				disabled: !((isOptimizedVCR || isOptimizedCPM) && isAutomated),
			});

			// fix_cpm
			this.setField("fix_cpm", {
				show: isFix,
				required: isFix,
				disabled: !isFix,
			});

			// expected_ctr
			this.setField("expected_ctr", {
				show: isOptimizedCPC && isAutomated,
				required: isOptimizedCPC && isAutomated,
				disabled: false,
			});

			// max_cpm_bid
			this.setField("max_cpm_bid", {
				show: isOptimizedCPC && isAutomated,
				required: isOptimizedCPC && isAutomated,
				disabled: !(isOptimizedCPC && isAutomated),
			});

			// target_ecpc
			this.setField("target_ecpc", {
				show: isOptimizedCPC && isAutomated,
				required: isOptimizedCPC && isAutomated,
				disabled: !(isOptimizedCPC && isAutomated),
			});

			// target_vcr
			this.setField("target_vcr", {
				show: isOptimizedVCR && isAutomated,
				required: isOptimizedVCR && isAutomated,
				disabled: !(isOptimizedVCR && isAutomated),
			});
		},

		async loadGrafico(){
			if (this.hasID) {
				await this.updateGraphic("1");
			}
		},

		async loadInitResources() {
			const lineItem = this.hasID
				? await this.getLineItemByID(this.getID)
				: initLineItem();

			await this.setLineItem(lineItem);

			//this.targeting_predicates = await this.dispatchTargetingPredicates();

			this.resources.budget_types = await this.dispatchBudgetTypes();

			this.resources.bid_strategies = await this.dispatchBidStrategies();

			this.resources.strategies = await this.dispatchStrategies();

			this.resources.line_pacings = await this.dispatchLinesPacing();
		},

		async loadEditResources() {
			if (this.hasID) {
				await this.setFilters(this.getID);

				//this.targeting = await this.fetchTargetingExpressions();

				this.resources.optimization_strategies =
					await this.dispatchOptimizationStrategies();

				await this.setSelectedCampaign(this.line_item?.campaign);

				this.setField("budget_type_id", {
					show: true,
					required: true,
					disabled: true,
					value: this.resources.selected_campaign?.budget_type_id,
				});

				const isByLine: Boolean =
					this.resources.selected_campaign?.optimization_strategy
						?.description === OPTIMIZATION_BY_LINE;

				if (isByLine) {
					// line_pacing_id
					this.setField("line_pacing_id", {
						show: isByLine,
						required: true,
						disabled: !isByLine,
						value: this.line_item?.line_pacing_id,
					});

					this.resources.line_pacings =
						await this.dispatchLinesPacing();
				}

				const isPacingDaily: Boolean = await this.isLinePacing(
					LINE_PACING_DAILY
				);

				if (isPacingDaily) {
					// daily_budget
					this.setField("daily_budget", {
						show: isPacingDaily,
						required: isPacingDaily,
						disabled: !isPacingDaily,
						value: this.line_item?.daily_budget,
					});

					const days = this.getCalculateDuration();

					// daily_budget_suggested
					this.setField("daily_budget_suggested", {
						show: isPacingDaily,
						required: false,
						disabled: true,
						value: days,
					});

					this.handleChange({ key: "line_duration", value: days });
				}

				this.resources.campaigns = [
					{
						id: this.line_item?.campaign_id,
						value: this.line_item?.campaign.name,
					},
				];

				if (this.line_item?.creative_method_id) {
					this.resources.creative_weighting_methods =
						await this.dispatchCreativeWeightingMethods();
				} else {
					this.line_item.creative_method_id =
						await this.getRamdomWeight();
				}

				if (!isEmpty(this.line_item?.frequency_caps)) {
					this.resources.unit_times = await this.dispatchUnitTimes();
					this.resources.types_frecuency_caps = await this.dispatchTypesFrecuencyCaps();
				}

				if (this.line_item?.line_item_type_id) {
					this.resources.line_item_types = await this.dispatchTypes();
				}

				this.dataGraphic.period = await this.periodGraphicBidWin();
				this.bid_win.id = this.line_item?.id;

				await this.updateStrategies();

				await this.loadDeliveryBidding();
			} else {
				if (this.hasCampaignID) {
					const response = await this.dispatchGetCampaignById(
						this.getCampaignID
					);
					this.line_item.campaign = response;
					this.line_item.campaign_id = response.id;
					await this.setSelectedCampaign(this.line_item?.campaign);
					await this.applyCampaignSelectedToLineItem(true);
					this.resources.campaigns = [
						{
							id: this.line_item?.campaign_id,
							value: this.line_item?.campaign.name,
						},
					];
					this.resources.optimization_strategies =
						await this.dispatchOptimizationStrategies();
				}

				const days = this.getCalculateDuration();
				this.handleChange({ key: "line_duration", value: days });

				if (isEmpty(this.resources.creative_weighting_methods)) {
					this.resources.creative_weighting_methods =
						await this.dispatchCreativeWeightingMethods();
					this.line_item.creative_method_id =
						await this.getRamdomWeight();
				} else {
					this.line_item.creative_method_id =
						await this.getRamdomWeight();
				}
			}
		},

		async loadFrecuencyType(){
			if (!isEmpty(this.line_item?.frequency_caps)) {
				const val = this.line_item.frequency_caps[0];
				if(val.hasOwnProperty("type_id")){
					const f_cap_type = await this.resources.types_frecuency_caps.find((e) => e.extra == val.type_id);
					this.resources.type_frecuency_caps_selected = f_cap_type.id;
				}
			}
		},

		async updateGraphic(period: any){
			if(isEmpty(period)) return;
			this.dataGraphic.loadingGraph = true;
			let hora = await this.getPerdiodo(period);
			let current_Date = this.moment().format(DEFAULT_DATE_TIME_FORMAT);
			let date = this.moment().subtract(hora, 'hours').format(
				DEFAULT_DATE_TIME_FORMAT
			);
			this.bid_win.start_date = date;
			this.bid_win.end_date = current_Date;
			await this.fetchDataGraphicBidWin()
			.then((response) => this.dataGraphic.loadingGraph = false)
			.catch((err) => this.dataGraphic.loadingGraph = false);
		},

		async getPerdiodo(period_id: any){
			return this.dataGraphic.period.find((p) => p.id == period_id).hora;
		},

		async fetchDataGraphicBidWin(){
			this.dataGraphic.data = await this.dataGraphicBidWin(this.bid_win);
		},

		async redirectTo(lineItemId?: any, index?: any) {
			let route: any = { name: "LineItemList" };

			if (lineItemId) {
				route = {
					name: "LineItemEdit",
					params: { id: lineItemId },
					//query: { tabIndex: index },
				};
			}

			this.setNotification({ title: "", message: "", type: "" });

			return this.$router.push(route);
		},

		isCurrentTabSelected(item: { key: number }, index: number): Boolean {
			return item.key === index;
		},

		async addFrecuencyCaps() {
			if (this.line_item.frequency_caps.length >= 3) return;
			this.line_item.frequency_caps.push({
				impressions: undefined,
				every_time: undefined,
				unit_time_id: undefined,
				type: this.resources.type_frecuency_caps_selected
			});
		},

		async updateTypesFrecuencyCaps(){
			this.line_item.frequency_caps.forEach(element => {
				element.type = this.resources.type_frecuency_caps_selected
			});
		},

		async updateCtrVcr() {
			if (this.line_item?.expected_ctr) {
				this.line_item.expected_ctr =
				(this.line_item.expected_ctr * 100).toFixed(2);
			}

			if (this.line_item?.target_vcr) {
				this.line_item.target_vcr *= 100;
			}
		},

		/**
		 * DISPATCH || GET
		 */

		async dispatchTargetingKeys() {
			return this.$store.dispatch("targeting/getTargetingKeys");
		},

		async dispatchTargetingPredicates() {
			return this.$store.dispatch("targeting/getTargetingPredicates");
		},

		async dispatchCampaigns() {
			//if (!isEmpty(this.resources.campaigns)) return this.resources.campaigns;
			return this.$store.dispatch("campaign/list", {
				filters: {} as CampaingFilters,
				options: { sort: "name", order: "asc" } as CampaingOptions,
			});
		},

		async dispatchTypes() {
			if (!isEmpty(this.resources.line_item_types))
				return this.resources.line_item_types;
			return this.$store.dispatch("custom_list/getLineItemTypes", {
				filters: {} as any,
				options: { sort: "name", order: "asc" } as any,
			});
		},

		// async dispatchCampaignPacing() {
		// 	return this.$store.dispatch("custom_list/getCampaignPacing");
		// },

		async dispatchBudgetTypes() {
			if (!isEmpty(this.resources.budget_types))
				return this.resources.budget_types;
			return this.$store.dispatch("custom_list/getBudgetTypes");
		},

		async dispatchOptimizationStrategies() {
			if (!isEmpty(this.resources.optimization_strategies))
				return this.resources.optimization_strategies;
			return this.$store.dispatch(
				"custom_list/getOptimizationStrategies"
			);
		},

		async dispatchStrategies() {
			if (!isEmpty(this.resources.strategies))
				return this.resources.strategies;
			return this.$store.dispatch("custom_list/getStrategies");
		},

		async dispatchUnitTimes() {
			if (!isEmpty(this.resources.unit_times))
				return this.resources.unit_times;
			return this.$store.dispatch("custom_list/getUnitTimes");
		},

		async dispatchCreativeWeightingMethods() {
			if (!isEmpty(this.resources.creative_weighting_methods))
				return this.resources.creative_weighting_methods;
			return this.$store.dispatch(
				"custom_list/getCreativeWeightingMethods"
			);
		},

		async dispatchBidStrategies() {
			if (!isEmpty(this.resources.bid_strategies)){
				return this.resources.bid_strategies;
			}
			this.resources.bid_strategies = await this.$store.dispatch("custom_list/getBidStrategies");
			// Se comenta debido a que no actualiza lista al cambiar el tipo de optimización
			return this.resources.bid_strategies;
		},

		async dispatchLinesPacing() {
			if (!isEmpty(this.resources.line_pacings))
				return this.resources.line_pacings;
			return this.$store.dispatch("custom_list/getLinesPacing");
		},

		async dispatchBiddingShadings() {
			if (!isEmpty(this.resources.bidding_shadings))
				return this.resources.bidding_shadings;
			return this.$store.dispatch("custom_list/getBiddingShadings");
		},

		async dispatchGetCampaignById(id: number) {
			return this.$store.dispatch("campaign/getById", id);
		},

		async dispatchTypesFrecuencyCaps() {
			if (!isEmpty(this.resources.types_frecuency_caps))
				return this.resources.types_frecuency_caps;
			return this.$store.dispatch("custom_list/getTypesFrecuencyCaps");
		},

		async nextTabEnabled(value: number){
			let tabNumber = null;
			this.items.some((t) => {
				if(t.key > value && !t.disabled){
					tabNumber = t.key;
					return true;
				}
			});
			return isNull(tabNumber) ? null : tabNumber;
		},

		/**
		 * HANDLERS
		 */

		async handleCancel() {
			try {
				this.$router.push({ name: "LineItemList" });
			} catch (error) {
				console.error("handleCancel", { error: error });
			}
		},

		async handleSubmit(params: {
			method: string;
			continue: Boolean;
			data: any;
		}) {
			try {
				await this.setLoadingData(TypeLoading.loading);

				switch (params.method) {
					case "create":
						const created = await this.createLineItem(params);
						const lineUpdate = await this.getLineItemByID(created.id);
						if (created && params.continue) {
							await this.setLineItem(lineUpdate);
							await this.setLine(lineUpdate);
							await this.updateCtrVcr();
							await this.setFilters(created.id);
							await this.redirectTo(created.id, 1);
							this.updateSelectedTabIndex(1);
							//await this.setIndexTab();
							window.scrollTo(0, 0);
							
						}
						break;

					case "update":
						const editParams: any = {
							id: this.getID,
							continue: params.continue,
							data: params.data,
						};
						const updated = await this.updateLineItem(editParams);
					

						if (updated && params.continue) {
							await this.setLineItem(updated);
							await this.setFilters(updated.id);
							//await this.redirectTo(updated.id, 1);
							this.updateSelectedTabIndex(1);
							//await this.setIndexTab();
							window.scrollTo(0, 0);
						}
						break;
				}

				await this.setLoadingData();
			} catch (error) {
				console.error("handleSubmit", { error: error });
				await this.setLoadingData();
			}
		},

		/**
		 * From CardActions - targetings
		 * @param action
		 */

		async handleTargetingAction(action: any) {
			let result = null;
			switch (action.type) {
				case "save":
					result = await this.handleTargetingSubmit();
					if (result) {
						this.$router.push({ name: "LineItemList" });
					}
					break;

				case "save-continue":
					result = await this.handleTargetingSubmit();
					if (result) {
						this.updateSelectedTabIndex(2);
					}
					break;

				case "cancel":
					await this.handleCancel();
					break;
			}
		},

		async handleTargetingSubmit() {
			try {
				await this.setLoadingData(TypeLoading.loading);

				const targeting_data = {
					id: this.getTargetingExpressionsID,
					line_item_id: this.line_item.id,
					active: true,
					targeting_terms: prepareTargetingDataCreate(
						this.targeting
					) as Array<Term>,
				};

				let targetingExpressions: any = null;

				if (this.hasTargetingExpressionsID) {
					targetingExpressions = await this.updateTargeting(
						targeting_data as TargetingDataUpdate
					);
				} else {
					targetingExpressions = await this.createTargeting(
						targeting_data as TargetingDataCreate
					);
				}

				await this.setLoadingData();

				return targetingExpressions;
			} catch (error) {
				console.error("handleTargetingSubmit", { error: error });
				await this.setLoadingData();
				return null;
			}
		},

		async handleClear(key: any) {
			switch (key) {
				case "clear-relations":
					this.line_item.start_date = null;
					this.line_item.end_date = null;
					this.line_item.line_duration = null;
					this.resources.selected_campaign = {
						advertiser: { id: null, name: null },
					};

					// advertiser_id
					this.setField("advertiser_id", {
						show: true,
						required: true,
						disabled: true,
						value: null,
					});

					// budget_type_id
					this.setField("budget_type_id", {
						show: true,
						required: true,
						disabled: false,
						value: null,
					});

					// budget
					this.setField("budget", {
						show: true,
						required: true,
						disabled: false,
						value: null,
					});

					// line_pacing_id
					this.setField("line_pacing_id", {
						show: false,
						required: false,
						disabled: true,
						value: null,
					});

					// strategy_id
					this.setField("strategy_id", {
						show: true,
						required: false,
						disabled: true,
						value: null,
					});

					// bid_strategy_id
					this.setField("bid_strategy_id", {
						show: true,
						required: true,
						disabled: true,
						value: null,
					});

					// daily_budget
					// this.setField("daily_budget", {
					// 	show: false,
					// 	required: false,
					// 	disabled: true,
					// 	value: null
					// });

					// cpm_bid
					// this.setField("cpm_bid", {
					// 	show: false,
					// 	required: false,
					// 	disabled: true,
					// 	value: null
					// });

					await this.updateBudgetDisplay();

					break;

				default:
					break;
			}
		},

		async handleActivate() {
			try {
				await this.setLoadingData(TypeLoading.loading);

				await this.activate(this.line_item.id);

				await this.setLoadingData();
			} catch (error) {
				console.error("handleActivate", { error: error });
				await this.setLoadingData();
			}
		},

		async updateSelectedTabIndex(index: number = 0) {
			if(this.items.find((t) => t.key == index).disabled){
				let next = await this.nextTabEnabled(index);
				if(isNull(next)){
					await this.redirectTo();
				}else{
					this.currentTab = next;
				}
			}else{
				this.currentTab = index;
			}

			if (index === 1) {
				await this.setLoadingData(TypeLoading.loading);
				this.targeting_predicates =
					await this.dispatchTargetingPredicates();

				if (this.hasID) {
					this.targeting = await this.fetchTargetingExpressions();
				}

				this.loadTargeting = false;

				await this.setLoadingData();
			}
		},

		updateSelectedTabTargetingIndex(index: number) {
			this.currentTabTargeting = index;
		},

		/**
		 *  ACTIONS STORE
		 */
		async createLineItem(params: {
			method: string;
			continue: Boolean;
			data: LineItemDataCreate;
		}) {
			return this.$store.dispatch("line_item/create", params);
		},

		async updateLineItem(params: {
			method: string;
			continue: Boolean;
			data: LineItemDataUpdate;
		}) {
			return this.$store.dispatch("line_item/update", params);
		},

		async activate(id: number) {
			return this.$store.dispatch("line_item/activate", id);
		},

		async createTargeting(targeting: TargetingDataCreate) {
			return this.$store.dispatch("targeting/create", targeting);
		},

		async updateTargeting(targeting: TargetingDataUpdate) {
			return this.$store.dispatch("targeting/update", targeting);
		},

		setNotification(notification: Notification) {
			return this.$store.dispatch("proccess/setNotification", notification, {
				root: true,
			});
		},

		setLabel(key: string, value: any) {
			this.fieldLabel[key] = value;
		},

		/**
		 * key
		 * element: required?, show?, disabled?, value
		 */
		setField(
			key: string,
			element: { required: any; show: any; disabled: any; value: any }
		) {
			this.resources.fields[key] = {
				required: element.required,
				show: element.show,
				disabled: element.disabled,
			};
			if (!isUndefined(element.value)) {
				this.line_item[key] = element.value;
			}
		},

		/**
		 * Add item
		 */
		addItem(params: {
			tab: string | number;
			key: string | number;
			value: any;
			value_name: string,
			verb_usage?: number;
			segment_name?: string;
			verb_usage_name?: string;
			is_category?: boolean;
		}) {
			const term: any = {
				value: String(params.value),
				TermSelected: [{id: params.value, value: params.value_name}],
				targeting_key_id:
					getTargetingIDByValue()[params.tab][params.key],
				targeting_predicate_id: isUndefined(params.verb_usage)
					? this.isIncluded
					: this.getPredicatedVerbUsage(params.verb_usage),
				verb_usage: isUndefined(params.verb_usage)
					? undefined
					: params.verb_usage,
				segment_name: isUndefined(params.segment_name)
					? undefined
					: params.segment_name,
				verb_usage_name: isUndefined(params.verb_usage_name)
					? undefined
					: params.verb_usage_name,
				is_category: isUndefined(params.is_category)
					? undefined
					: params.is_category,
			};

			const existTerm: any[] = this.targeting[params.tab][
				params.key
			].targeting_terms.filter(
				(tt: { value: any }) => tt.value == term.value
			);

			if (existTerm.length < 1) {
				this.targeting[params.tab][params.key].targeting_terms.push(
					term
				);
			}
		},

		getPredicatedVerbUsage(verb) {
			switch (verb) {
				case 604:
					return this.isIncluded;
				case 605:
					return this.isIncluded;
				case 606:
					return this.isExcluded;
				case 607:
					return this.isRequired;
				default:
					return this.isIncluded;
			}
		},

		/**
		 * Add item unique
		 */
		addItemUnique(params: {
			tab: string | number;
			key: string | number;
			value: any;
			value_name: string,
			reset: Boolean | undefined;
			extra?: string;
		}) {
			if (!isEmpty(trim(params.value))) {
				if (
					!isEmpty(
						this.targeting[params.tab][params.key].targeting_terms
					)
				) {
					if (
						!this.targeting[params.tab][
							params.key
						].targeting_terms[0].value.includes(trim(params.value))
					) {
						this.targeting[params.tab][
							params.key
						].targeting_terms[0].value.push(trim(params.value));

						if(this.targeting[params.tab][params.key].targeting_terms[0].hasOwnProperty("TermSelected")){
							this.targeting[params.tab][
								params.key
							].targeting_terms[0]['TermSelected'].push({id: params.value, value: params.value_name, extra: params.extra});
						}else{
							this.targeting[params.tab][
								params.key
							].targeting_terms[0]['TermSelected'] = [{id: params.value, value: params.value_name, extra: params.extra}];
						}
					}
				} else {
					this.targeting[params.tab][params.key].targeting_terms.push(
						{
							value: [trim(String(params.value))],
							targeting_key_id:
								getTargetingIDByValue()[params.tab][params.key],
							targeting_predicate_id: this.isIncluded,
							TermSelected: [{id: params.value, value: params.value_name, extra: params.extra}]
						}
					);
				}
			}

			if (!isUndefined(params.reset) && Boolean(params.reset)) {
				this.targeting[params.tab][params.key].value = "";
			}
		},

		updateItemUnique(params: { tab: any; key: any; items: Array<string> }) {
			let terms: Array<any> = [];

			params.items.forEach((item) => {
				if (isEmpty(trim(item))) return;
				if (isEmpty(terms)) {
					terms.push({
						value: [trim(String(item))],
						targeting_key_id:
							getTargetingIDByValue()[params.tab][params.key],
						targeting_predicate_id: this.isIncluded,
					});
				} else {
					if (!terms[0].value.includes(trim(item))) {
						terms[0].value.push(trim(String(item)));
					}
				}
			});

			this.targeting[params.tab][params.key].targeting_terms = terms;
		},

		/**
		 * Remove item unique
		 */
		removeItemUnique(params: { tab: any; key: any; value: any }) {
			/**
			 * Targeting Terms by {{key}}
			 */
			if (
				!isEmpty(this.targeting[params.tab][params.key].targeting_terms)
			) {
				const targetingTerm =
					this.targeting[params.tab][params.key].targeting_terms[0];

				if (isEmpty(targetingTerm.value)) return;

				if(this.targeting[params.tab][params.key].targeting_terms[0].hasOwnProperty("TermSelected")){
					const index_term_selected = targetingTerm.TermSelected.findIndex(
						(v: any) => String(v.id) == String(params.value)
					);
					if (index_term_selected > -1) {
						this.targeting[params.tab][
							params.key
						].targeting_terms[0].TermSelected.splice(index_term_selected, 1);
					}
				}

				if(this.targeting[params.tab][params.key].targeting_terms[0].hasOwnProperty("value_name")){
					const index_name = targetingTerm.value.findIndex(
						(v: string) => v == String(params.value)
					);
					if (index_name > -1) {
						this.targeting[params.tab][
							params.key
						].targeting_terms[0].value_name.splice(index_name, 1);
					}
				}

				const index = targetingTerm.value.findIndex(
					(v: string) => v == String(params.value)
				);

				if (index > -1) {
					this.targeting[params.tab][
						params.key
					].targeting_terms[0].value.splice(index, 1);

					if (
						isEmpty(
							this.targeting[params.tab][params.key]
								.targeting_terms[0].value
						)
					) {
						this.targeting[params.tab][params.key].targeting_terms =
							[];
					}
				}
			}

			/**
			 * targeting.{tab}.{key}.value
			 */

			if (!this.targeting[params.tab][params.key].value) return;

			if (isString(this.targeting[params.tab][params.key].value)) {
				const separator = ",";

				let splitted = String(
					this.targeting[params.tab][params.key].value
				).split(separator);

				const index = splitted.indexOf(String(params.value));

				if (index > -1) {
					splitted.splice(index, 1);

					this.targeting[params.tab][params.key].value =
						splitted.join(separator);
				}
			}

			if (isArray(this.targeting[params.tab][params.key].value)) {
				const _index = this.targeting[params.tab][
					params.key
				].value.findIndex((v: string) => v == String(params.value));

				if (_index > -1) {
					this.targeting[params.tab][params.key].value.splice(
						_index,
						1
					);
				}
			}
		},

		/**
		 * Remove item
		 */
		removeItem(params: { tab: any; key: any; value: any }) {
			/**
			 * Targeting Terms by {{key}}
			 */
			const index = this.targeting[params.tab][params.key].targeting_terms
				.filter(
					(t: any) =>
						t.targeting_key_id ==
						getTargetingIDByValue()[params.tab][params.key]
				)
				.findIndex(
					(t: { value: string }) => t.value == String(params.value)
				);

			if (index > -1) {
				this.targeting[params.tab][params.key].targeting_terms.splice(
					index,
					1
				);
			}

			/**
			 * targeting.{tab}.{key}.value
			 */
			const _index = this.targeting[params.tab][
				params.key
			].value.findIndex((v: string) => v == String(params.value));

			if (_index > -1) {
				this.targeting[params.tab][params.key].value.splice(_index, 1);
			}
		},

		/**
		 * Clear All items
		 */
		clearHandler(params: { key: any; tab: any }) {
			this.targeting[params.tab][params.key].value = [];
			this.targeting[params.tab][params.key].targeting_terms = [];
		},

		/**
		 * Update variables auxiliares
		 */
		updateDataVariables(params: any) {
			this.data_variables[params.tab][params.key] = params.data;
		},

		addCommaHandler(params: { tab: any; key: any }) {
			this.targeting[params.tab][params.key].value =
				this.targeting[params.tab][params.key].value.concat(",");
		},

		/**
		 * Update Selected Number Range
		 */
		updateSelectedChecked(params: {
			tab: any;
			key: any;
			value: any;
			targeting_predicate_id: Number;
		}) {
			const finded = find(
				this.targeting[params.tab][params.key].targeting_terms,
				{
					value: String(params.value),
				}
			);

			if (!isEmpty(finded)) {
				this.targeting[params.tab][params.key].targeting_terms = filter(
					this.targeting[params.tab][params.key].targeting_terms,
					function (s) {
						return s.value != String(params.value);
					}
				);
			} else {
				this.targeting[params.tab][params.key].targeting_terms.push({
					value: String(params.value),
					targeting_key_id:
						this.targeting[params.tab][params.key].targeting_key_id,
					targeting_predicate_id: params.targeting_predicate_id,
				});
			}
		},

		updateSelectedPredicate(params: { tab: any; key: any; value: any }) {
			this.targeting[params.tab][params.key].targeting_terms.forEach(
				(t: { targeting_predicate_id: any }) => {
					t.targeting_predicate_id = params.value;
				}
			);
		},

		updateCurrencyTab(index: number){
			this.currentTabTargeting = 0;
		},

		initTargetingTime(params) {
			this.targeting[params.tab][params.key].targeting_terms =
				params.targeting_terms;
		},

		/**
		 * From CardActions - Modifiers/Models
		 * @param action
		 */

		async handleSubmitModifier(params: any) {
			try {
				this.setLoadingData(TypeLoading.loading);
				const result = await this.dispatchCreateModifier(
					params.entity,
					params?.isBidModifier,
					params?.isDeliveryModifier
				);

				if(params?.isBidModifier){
					this.getLineItem.modifier_id = result.id;
				}
				if(params?.isDeliveryModifier){
					this.getLineItem.delivery_modifier_id = result.id;
				}
				
				this.updateCountBidModifier = false;
				if (isUndefined(result) || isNull(result) || isEmpty(result)) {
					this.setNotification({
						title: this.$t("title-failed"),
						message: this.$t("failed"),
						btn_text: this.$t("try-again"),
						type: MessageTypes.ERROR,
						show: true,
					});
				} else {
					this.setNotification({
						title: this.$t("title-success"),
						message: this.$t("success"),
						btn_text: this.$t("continue"),
						type: MessageTypes.SUCCESS,
						show: true,
					});
					await params.executeDispatchFunction();
					await params.executeClearFunction();
				}
			} catch (error) {
				console.error("handleSubmitModifier", { error: error });
			}
			await this.setLoadingData();
		},

		async handleCreateAssociation(params: any) {
			try {
				this.setLoadingData(TypeLoading.loading);
				const result = await this.dispatchCreateAssociation(
					params.module,
					params.entity
				);
				if (
					!isUndefined(result) &&
					!isNull(result) &&
					!isEmpty(result)
				) {
					if (params.continue) {
						this.setNotification({
							title: this.$t("title-success"),
							message: this.$t("success"),
							btn_text: this.$t("continue"),
							type: MessageTypes.SUCCESS,
							show: true,
						});
						//this.$router.push({ name: "CreativesCreate" });
						this.updateSelectedTabIndex(3);
					} else {
						this.setNotification({
							title: this.$t("title-success"),
							message: this.$t("success"),
							btn_text: this.$t("continue"),
							type: MessageTypes.SUCCESS,
							show: true,
						});
						this.$router.push({ name: "LineItemList" });
					}
				}
			} catch (error) {
				console.error("handleCreateAssociation", { error: error });
			}
			await this.setLoadingData();
		},

		updateSelectedTabModifiersIndex(index: number) {
			this.currentTabModifiers = index;
		},

		async handleSaveContinueCreative(){
			try {
				await this.setLoadingData(TypeLoading.loading);
				this.setNotification({
					title: this.$t("title-success"),
					message: this.$t("success"),
					btn_text: this.$t("continue"),
					type: MessageTypes.SUCCESS,
					show: true,
				});
				this.updateSelectedTabIndex(4);
				await this.setLoadingData();
			} catch (error) {
				console.error("handleSaveContinueCreative", { error: error });
				await this.setLoadingData();
			}
			
		},

		async dispatchCreateAssociation(module: string, entity: any) {
			let result: any = undefined;
			switch (module) {
				case "BID_MODIFIER":
					result =
						await this.dispatchCreateAssociationWithBidModifier(
							entity
						);
					break;
				case "DELIVERY_MODIFIERS":
					result = await this.dispatchCreateAssociationWithBidModifier(
						entity
						);
					break;
			}
			return result;
		},

		async dispatchCreateAssociationWithBidModifier(entity: any) {
			return await this.$store.dispatch(
				"line_item/createAssociationWithModifier",
				{ entity: entity }
			);
		},

		async dispatchCreateModifier(
			entity: any,
			isBidModifier: Boolean = false,
			isDeliveryModifier: Boolean = false
		) {
			return this.$store.dispatch("modifier/createModifier", {
				modifier: entity,
				isBidModifier: isBidModifier,
				isDeliveryModifier: isDeliveryModifier,
			});
		},

		updateFieldsForCampaignPMP: debounce(async function (){
			if(this.resources.selected_campaign.campaign_pmp){
				const idStrategy = await this.getStrategyByValue();
				const idBidStrategy = await this.getBidStrategyByValue();
				this.line_item.bid_strategy_id = idBidStrategy?.id;
				this.line_item.strategy_id = idStrategy?.id;
				if(this.isCampaignOptimizationByLine()){
					const idLinePacing = await this.getLinePacingByValue();
					this.line_item.line_pacing_id = idLinePacing.id;
				}
			}
		}, 300),
	},
	watch: {
		async "line_item.max_cpm_bid"(val, old) {
			if (isUndefined(old)){
				this.line_item.expected_ctr = "";
				return;
			}
			await this.calcExpectedCTR();
			/*
			if(!this.flag_clac_cpm_bid){
				await this.calcMaxCpmBid();
			}*/
		},
		async "line_item.target_ecpc"(val, old) {
			if (isUndefined(old)){
				this.line_item.expected_ctr = "";
				return;
			}
			await this.calcExpectedCTR();
			/*
			if(!this.flag_clac_cpm_bid){
				await this.calcMaxCpmBid();
			}*/
		},
		async "line_item.expected_ctr"(val, old) {
			if (isUndefined(old)) return;
			/*
			if(!this.flag_clac_cpm_bid){
				await this.calcMaxCpmBid();
			}*/
		},
		async "resources.type_frecuency_caps_selected"(val, old){
			await this.updateTypesFrecuencyCaps();
		},
		async "getFrecuencyCapType"(val, old){
			await this.loadFrecuencyType();
		},
		async "currentTab"() {
			this.currentTabTargeting = 8;
		}
	},
});


import CreateTabs from "@/components/Commons/CreateTabs.vue";
import Vue from "vue";
import { isEmpty, size } from "lodash";

export default Vue.extend({
	name: "NavTargeting",
	props: {
		current_tab: {
			type: Number,
			default: 0
		},
		targeting: {
			type: Object,
			default: function() {
				return {};
			}
		}
	},
	components: { CreateTabs },
	data: () => ({}),
	created() {},
	async mounted() {},
	computed: {
		getItems() {
			return [
				{
					key: 0,
					tab: this.$t('modifier.tabs.app_site'),
					disabled: false,
					show: true,
					count: this.getCountTargetingKeys("app_site"),
					src: require(`@/assets/icons/appsite_icon.png`)
				},
				{
					key: 1,
					tab: this.$t('modifier.tabs.content'),
					disabled: false,
					show: true,
					count: this.getCountTargetingKeys("content"),
					src: require(`@/assets/icons/content_icon.png`)
				},
				{
					key: 2,
					tab: this.$t('modifier.tabs.environment'),
					disabled: false,
					show: true,
					count: this.getCountTargetingKeys("environment"),
					src: require(`@/assets/icons/environment_icon.png`)
				},
				{
					key: 3,
					tab: this.$t('modifier.tabs.exchange'),
					disabled: false,
					show: true,
					count: this.getCountTargetingKeys("exchange"),
					src: require(`@/assets/icons/exchange_icon.png`)
				},

				{
					key: 4,
					tab: this.$t('modifier.tabs.geo'),
					disabled: false,
					show: true,
					count: this.getCountTargetingKeys("geo"),
					src: require(`@/assets/icons/geo_icon.png`)
				},
				{
					key: 5,
					tab: this.$t('modifier.tabs.platform'),
					disabled: false,
					show: true,
					count: this.getCountTargetingKeys("platform"),
					src: require(`@/assets/icons/platform_icon.png`)
				},
				{
					key: 6,
					tab: this.$t('modifier.tabs.time'),
					disabled: false,
					show: true,
					count: this.getCountTargetingKeys("time"),
					src: require(`@/assets/icons/time_icon.png`)
				},
				{
					key: 7,
					tab: this.$t('modifier.tabs.user'),
					disabled: false,
					show: true,
					count: this.getCountTargetingKeys("user"),
					src: require(`@/assets/icons/user_icon.png`)
				},
				{
					key: 8,
					tab: "Summury",
					disabled: true,
					show: false,
					count: 0,
					src: ""
				}
			];
		}
	},
	methods: {
		updateSelectedTabIndex(index: number) {
			this.$emit("update-selected-tab-index", index);
		},
		getCountTargetingKeys(key: any): Number {
			const tk = this.targeting[key];

			if (isEmpty(tk)) return 0;

			const values: Array<any> = Object.values(tk).filter((t: any) => {
				return !isEmpty(t.targeting_terms);
			});

			return size(values);
		}
	}
});

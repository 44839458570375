

import Vue from "vue";
import ItemTargeting from "./itemTargeting.vue";
import ItemTargetingSummary from "./itemTargetingSummary.vue";
//@ts-ignore
import IconBase from "@/components/Commons/Icons/IconBase.vue";
import { mapGetters } from "vuex";
import { isArray } from "lodash";

export default Vue.extend({
	name: "TargetingSummaryItem",
	props: {},
	components: { IconBase, ItemTargeting, ItemTargetingSummary },
	data: () => ({}),
	created() {},
	mounted() {},
	computed: {
		...mapGetters("targeting",["getTargetingSummary"]),

		getTargeting(){
			let datos = new Array();
			Object.entries(this.getTargetingSummary).forEach(([key, value]) => {
				Object.entries(this.getTargetingSummary[key]).forEach(([k, v]) => {
					if(!isArray(this.getTargetingSummary[key][k]) || !isArray(this.getTargetingSummary[key][k])){
						if(!datos.some((v) => v.key == key)){
							datos.push({key: key, value: this.getTargetingSummary[key]});
						}
					}
				});
			});
			return datos;
		},

		constainData(){
			return this.getTargeting.length > 0;
		}
	},
	methods: {
		updateCurrencyTab(){
			this.$emit("update-currency-tab");
		}
	},
	watch: {},
});

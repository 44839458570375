export function getHeaderAssoLineCreative(){
    return [
        {
            text: "Id",
            align: "center",
            sortable: false,
            filterable: true,
            value: "id",
        },
        {
            text: "Thumbnail",
            align: "center",
            sortable: false,
            filterable: false,
            value: "creative_thumbnail",
        },
        {
            text: "Creative Id",
            align: "center",
            sortable: false,
            filterable: true,
            value: "creative_external_id",
        },
        {
            text: "Creative Name",
            align: "start",
            sortable: false,
            filterable: true,
            value: "creative_name",
        },
        {
            text: "Size",
            align: "start",
            sortable: false,
            filterable: true,
            value: "creative_size",
        },
        {
            text: "Start Date",
            align: "center",
            sortable: false,
            filterable: false,
            value: "start_date",
        },
        {
            text: "End Date",
            align: "center",
            sortable: false,
            filterable: false,
            value: "end_date",
        },
        {
            text: "",
            align: "center",
            sortable: false,
            value: "actions",
            width: "5%",
        }
    ];
}

export function getHeaderAssoCreativeLine(){
    return [
        {
            text: "ID",
            align: "start",
            sortable: false,
            filterable: true,
            value: "external_id",
            width: "5%",
        },
        {
            text: "Campaign ID",
            align: "center",
            sortable: false,
            filterable: true,
            value: "campaign_item_id",
        },
        {
            text: "Campaign Name",
            align: "center",
            sortable: false,
            filterable: true,
            value: "campaign_item_name",
        },
        {
            text: "Line Item ID",
            align: "center",
            sortable: false,
            filterable: true,
            value: "line_item_external_id",
        },
        {
            text: "Line Item Name",
            align: "start",
            sortable: false,
            filterable: true,
            value: "line_item_name",
        },
        {
            text: "Line Item Status",
            align: "center",
            sortable: false,
            filterable: false,
            value: "line_item_active",
        },
        {
            text: "Start Date",
            align: "center",
            sortable: false,
            filterable: false,
            value: "start_date",
        },
        {
            text: "End Date",
            align: "center",
            sortable: false,
            filterable: false,
            value: "end_date",
        },
        {
            text: "",
            align: "center",
            sortable: false,
            value: "actions",
            width: "5%",
        }
    ];
}


import Vue from "vue";
// @ts-ignore
import IconBase from "@/components/Commons/Icons/IconBase.vue";
import { isNull } from "lodash";
import { mapGetters } from "vuex";
export default Vue.extend({
	name: "ItemTargeting",
	props: {
		subtitle: String,
		include: {
			type: Object,
			default: function () {
				return {};
			}
		},
		exclude: {
			type: Object,
			default: function () {
				return {};
			}
		},
		color: {
			type: String,
			default: "secondary",
		},
	},
	components: { IconBase },
	data: () => ({

	}),
	created() { },
	mounted() { },
	computed: {
		...mapGetters("targeting", ["getTargetingSummary"]),
		isEmptyTar() {
			if (this.getInclude && this.getExclude) {
				return true;
			}
			else{
				return false;
			}

		},
		targertingEmpy(){
			return (
				Object.entries(this.getTargetingSummary > 0).filter(([key, value]) => value !== null && value !== undefined)
			);
		}

	},
	methods: {
		getInclude() {
			return (
				Object.entries(this.getTargetingSummary.all > 0).filter(([key, value]) => value !== null && value !== undefined)
			);
		},
		getExclude() {
			return (
				Object.entries(this.getTargetingSummary.none > 0).filter(([key, value]) => value !== null && value !== undefined)
			);
		},


		getUrl(item: { src: String }) {
			return item.src;
		},
		getIconType(key: any) {
			let icon = "";
			switch (key) {
				case 0:
					icon = "app-site";
					break;

				case 1:
					icon = "content";
					break;

				case 2:
					icon = "environment";
					break;

				case 3:
					icon = "exchange";
					break;

				case 4:
					icon = "geo";
					break;

				case 5:
					icon = "platform";
					break;

				case 6:
					icon = "time";
					break;

				case 7:
					icon = "user";
					break;
			}

			return icon;
		},
	},
	watch: {

	},
});

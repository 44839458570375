
import Vue from "vue";
//@ts-ignore
import CardSwitch from "@/components/Content/CardSwitch.vue";
//@ts-ignore
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import { uniqBy, isUndefined } from "lodash";

export default Vue.extend({
	name: "TermList",
	model: {
		prop: "appLists",
		event: "input",
	},
	props: {
		targeting_term: {
			type: Object,
			default: function () {
				return [];
			},
		},
		appLists: {
			type: Array,
			default: function () {
				return [];
			},
		},
		predicates: {
			type: Object,
			default: function () {
				return {};
			},
		},
		verb_usage_segment: {
			type: Number,
			default: 604,
		},
		verb_usage_list: {
			type: Array,
			default: function () {
				return [];
			},
		},
		readonly:{
			type:Boolean,
			default: false
		}
	},
	components: { CardSwitch, CardAutocomplete },
	data: () => ({
		dataList: [] as Array<any>,
		tab: "user",
		keyTerm: "segment",
	}),
	created() {},
	mounted() {},
	computed: {
		modelData: {
			set(val: any) {
				this.$emit("input", val);
			},
			get() {
				return this.appLists;
			},
		},
		isDisabled() {
			if (isUndefined(this.targeting_term.verb_usage))
				return this.getVerifiVerb();
			switch (this.verb_usage_segment) {
				case 604:
					return false;
				case 605:
					return true;
				case 606:
					return true;
				case 607:
					return true;
				default:
					return this.getVerifiVerb();
			}
		},
	},
	methods: {
		getVerifiVerb() {
			if (!this.targeting_term.hasOwnProperty("verb_usage_name")) return false;
			if (isUndefined(this.targeting_term.verb_usage_name)) return false;
			switch (this.targeting_term.verb_usage_name) {
				case "ALL":
					return false;
				case "INCLUDE":
					return true;
				case "EXCLUDE":
					return true;
				case "REQUIRE":
					return true;
				default:
					return false;
			}
		},

		getNameDisplay(targeting_term: any) {
			return (
				this.getDisplayNameByValue(targeting_term) +
				" - " +
				this.getDisplaySegmentNameByValue(targeting_term)
			);
		},

		getDisplayNameByValue(targeting_term: any) {
			const app = this.modelData.find(
				(item: any) => item.id == targeting_term?.value
			);
			if (app) return app.value;

			return targeting_term?.value_name || targeting_term?.value;
		},

		getDisplaySegmentNameByValue(targeting_term: any) {
			const app = this.modelData.find(
				(item: any) => item.id == targeting_term?.value
			);
			if (app) return app.value;

			return targeting_term?.segment_name;
		},

		removeHandler(value: any) {
			let param = {
				unique: false,
				params: {
					tab: this.tab,
					key: this.keyTerm,
					value: value.value,
					verb_usage: value.verb_usage,
					segment_name: value.segment_name,
				},
			};
			this.$emit("remove-item", param);
		},

		isIncluded(targeting_predicate_id: Number) {
			return this.predicates.INCLUDED === targeting_predicate_id;
		},

		getPredicatesSegmentos() {
			return [
				{
					value: "Include",
					id: this.predicates.INCLUDED,
				},
				{
					value: "Exclude",
					id: this.predicates.EXCLUDED,
				},
				{
					value: "Require",
					id: this.predicates.REQUIRED,
				},
			];
		},
	},
	watch: {
		appLists(val: Array<any>) {
			let newList: Array<any> = this.modelData;
			newList = newList.concat(val);
			newList = uniqBy(newList, "id");
			this.dataList = newList;
		},
	},
});

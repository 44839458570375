
import Vue from "vue";
//@ts-ignore
import CardSwitch from "@/components/Content/CardSwitch.vue";
// @ts-ignore
import IconBase from "@/components/Commons/Icons/IconBase.vue";
import TermSummaryItem from "./targetingSummaryItem.vue";
// @ts-ignore
import TabSummary from "./tabSummary.vue";
import { mapActions, mapGetters } from "vuex";
export default Vue.extend({
	name: "TargetingSummary",
	model: {},
	props: {
		line_item:{
			type:Object,
			default:()=>({})
		}
	},
	components: {
		CardSwitch,
		IconBase,
		TermSummaryItem,
		TabSummary
	},
	data: () => ({

	}),
	created() {},
	mounted() {
		if(this.line_item.id){
			this.fetchSummaryTargeting({id: this.line_item.id});
		}
	},
	computed: {
		...mapGetters("targeting",["getTargetingSummary"])
	},
	methods: {
		...mapActions("targeting",["fetchSummaryTargeting"]),

		updateCurrencyTab(){
			this.$emit("update-currency-tab", 0);
		}
	},
	watch: {

	},
});

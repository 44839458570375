
const subtitleMatches = {
	app_site: { title: "App Site", tr: "appSite" },
	content: { title: "Content", tr: "content" },
	environment: { title: "Environment", tr: "environment" },
	exchange: { title: "Exchange", tr: "exchange" },
	geo: { title: "Geo", tr: "geo" },
	platform: { title: "Platform", tr: "platform" },
	user: { title: "User", tr: "user" },
	time: { title: "Time", tr: "time" },
	video: { title: "Video", tr: "video" },
};

import Vue from "vue";
//@ts-ignore
import IconBase from "@/components/Commons/Icons/IconBase.vue";
import { mapGetters } from "vuex";
import { isArray } from "lodash";
//@ts-ignore
import i18n from "@/plugins/i18n";

export default Vue.extend({
	name: "ItemTargeting",
	props: {
		item: {
			type: Object,
			default: function () {
				return {};
			},
		},
	},
	components: { IconBase },
	data: () => ({}),
	created() {},
	mounted() {},
	computed: {
		...mapGetters("targeting", ["getTargetingSummary"]),

		getSubtitle() {
			return subtitleMatches[this.item.key].title;
		},

		isAll() {
			return !isArray(this.item.value.all);
		},

		isNone() {
			return !isArray(this.item.value.none);
		},
		isIdModule() {
			return this.item.key === "app_site";
		},
		isRequire() {
			return !isArray(this.item.value.require);
		},
		isContent() {
			return this.item.key === "content";
		},
		isTime() {
			return this.item.key === "time";
		},
		isUser() {
			return this.item.key === "user";
		},
		getAll() {
			let datos = new Array();
			Object.entries(this.item.value.all).forEach(([key, value]) => {
				datos.push({ key: key, value: this.item.value.all[key] });
			});
			return datos;
		},

		getNone() {
			let datos = new Array();
			Object.entries(this.item.value.none).forEach(([key, value]) => {
				datos.push({ key: key, value: this.item.value.none[key] });
			});
			return datos;
		},
		getRequire() {
			let datos = new Array();
			console.log("getRequire", { item: this.item});
			if(this.item?.value?.require) {
				Object.entries(this.item?.value?.require).forEach(([key]) => {
					datos.push({ key: key, value: this.item.value.require[key] });
				});
			}
			return datos;
		},
		getUserTimeNone() {
			let datos = new Array();
			if (this.item.value.none.user_time_of_week) {
				Object.entries(this.item.value.none.user_time_of_week).forEach(
					([key, value]) => {
						datos.push({
							key: key,
							value: this.item.value.none.user_time_of_week[key],
						});
					}
				);
			}
			return datos;
		},
		getUserTimeAll() {
			let datos = new Array();
			if (this.item.value.all.user_time_of_week) {
				Object.entries(this.item.value.all.user_time_of_week).forEach(
					([key, value]) => {
						datos.push({
							key: key,
							value: this.item.value.all.user_time_of_week[key],
						});
					}
				);
			}
			return datos;
		},
		getTr() {
			return subtitleMatches[this.item.key].tr;
		},
	},
	methods: {
		getTitleItem(key: string) {
			const tr = `modifier.labels.${this.getTr}.${key}.label`;
			return i18n.t(tr);
		},
		getIconType(key: any) {
			let icon = "";
			switch (key) {
				case 0:
					icon = "app-site";
					break;

				case 1:
					icon = "content";
					break;

				case 2:
					icon = "environment";
					break;

				case 3:
					icon = "exchange";
					break;

				case 4:
					icon = "geo";
					break;

				case 5:
					icon = "platform";
					break;

				case 6:
					icon = "time";
					break;

				case 7:
					icon = "user";
					break;
			}
			return icon;
		},
	},
	watch: {},
});

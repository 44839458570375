
import Vue from "vue";
import TabItem from "./tab_items/TabItem.vue";
import { isEmpty, isUndefined } from "lodash";
import { mapActions } from "vuex";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";

export default Vue.extend({
	name: "Platform",
	props: {
		platform: {
			type: Object,
			default: function() {}
		},
		data_variables: {
			type: Object,
			default: function() {
				return {};
			}
		},
		predicates: {
			type: Object,
			default: function() {
				return {};
			}
		},
		is_edit: {
			type: Boolean,
			default: false
		},
		readonly:{
			type:Boolean,
			default: false
		}
	},
	components: {
		TabItem
	},
	data: () => ({
		tab: "platform"
	}),
	async created() {},
	async mounted() {
		await this.requiredResources();
	},
	computed: {},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		async requiredResources() {
			if (this.is_edit) {
				const keys = Object.keys(this.platform);

				keys.forEach(async key => {
					if (
						!isUndefined(this.platform[key]["targeting_terms"]) &&
						!isEmpty(this.platform[key]["targeting_terms"])
					) {
						await this.getComboData(key);
					}
				});
			}
		},

		async getComboData(key: string) {
			try {
				let params = {};

				if (isEmpty(this.data_variables[key])) {
					switch (key) {
						case "browser":
							params = {
								key: "extra",
								value: "description"
							};

							break;

						case "browser_version":
							params = {
								key: "key",
								value: "description"
							};

							break;

						case "carrier":
							params = {
								key: "extra",
								value: "description"
							};

							break;

						case "device_type":
							params = {
								key: "extra",
								value: "description"
							};

							break;

						case "device_make":
							params = {
								key: "extra",
								value: "description"
							};

							break;

						case "device_model":
							params = {
								key: "key",
								value: "description"
							};

							break;

						case "device_screen_size":
							params = {
								key: "key",
								value: "description"
							};

							break;

						case "operating_system":
							params = {
								key: "extra",
								value: "description"
							};

							break;

						case "operating_system_version":
							params = {
								key: "key",
								value: "description"
							};

							break;
					}

					this.setLoadingData(TypeLoading.loading)

					this.$emit("update-data-var", {
						tab: this.tab,
						key: key,
						params: params,
						data: await this.dispatchTargetingKey(key, params)
					});

					await this.setLoadingData();
				}
			} catch (error) {
				console.error("getComboData", { key, error });
				await this.setLoadingData();
			}
		},

		async dispatchTargetingKey(key: String, object?: any) {
			return this.$store.dispatch("targeting/getTargetingKey", {
				key: key,
				object: object
			});
		},

		clearHandler(key: any) {
			this.$emit("clear-app-site", { tab: this.tab, key: key });
		},

		removeHandler(key: any, value: any, is_unique: Boolean = false) {
			this.$emit(is_unique ? "remove-item-unique" : "remove-item", {
				tab: this.tab,
				key: key,
				value: value,
				is_unique: is_unique
			});
		},

		async updateWatchByKey(
			key: String,
			val: Array<any>,
			old: Array<any>,
			is_unique: Boolean = false
		) {
			if (val.length > old.length) {
				const item = val.filter(function(o: any) {
					return !old.includes(o);
				});
				this.$emit(is_unique ? "add-item-unique" : "add-item", {
					tab: this.tab,
					key: key,
					value: item[0]
				});
			}
			if (val.length < old.length) {
				const item = old.filter(function(o: any) {
					return !val.includes(o);
				});
				this.$emit(is_unique ? "remove-item-unique" : "remove-item", {
					tab: this.tab,
					key: key,
					value: item[0]
				});
			}
		}
	},

	watch: {
		async "platform.browser.value"(val: Array<any>, old: Array<any>) {
			await this.updateWatchByKey("browser", val, old, true);
		},
		async "platform.browser_version.value"(
			val: Array<any>,
			old: Array<any>
		) {
			await this.updateWatchByKey("browser_version", val, old, true);
		},
		async "platform.carrier.value"(val: Array<any>, old: Array<any>) {
			await this.updateWatchByKey("carrier", val, old, true);
		},
		async "platform.device_type.value"(val: Array<any>, old: Array<any>) {
			await this.updateWatchByKey("device_type", val, old, true);
		},
		async "platform.device_make.value"(val: Array<any>, old: Array<any>) {
			await this.updateWatchByKey("device_make", val, old, true);
		},
		async "platform.device_model.value"(val: Array<any>, old: Array<any>) {
			await this.updateWatchByKey("device_model", val, old, true);
		},
		async "platform.device_screen_size.value"(
			val: Array<any>,
			old: Array<any>
		) {
			await this.updateWatchByKey("device_screen_size", val, old, true);
		},
		async "platform.operating_system.value"(
			val: Array<any>,
			old: Array<any>
		) {
			await this.updateWatchByKey("operating_system", val, old, true);
		},
		async "platform.operating_system_version.value"(
			val: Array<any>,
			old: Array<any>
		) {
			await this.updateWatchByKey(
				"operating_system_version",
				val,
				old,
				true
			);
		}
	}
});

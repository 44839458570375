
import Vue from "vue";
import CardAutocomplete from "../../../../../../components/Content/CardAutocomplete.vue";
import TermList from "../tab_contents/termList.vue";
import TermListUnique from "../tab_contents/termListUnique.vue";

export default Vue.extend({
	name: "TabItem",
	model: {
		prop: "appLists",
		event: "input",
	},
	props: {
		targeting_key_data: {
			type: Object,
			default: function() {
				return {};
			}
		},
		attributeList: {
			type: Array,
			default: function() {
				return [];
			}
		},
		appLists: {
			type: Array,
			default: function() {
				return [];
			}
		},
		hint: {
			type: String,
			default: "Hint"
		},
		reference: {
			type: String,
			default: "reference"
		},
		placeholder: {
			type: String,
			default: "Placeholder"
		},
		label: {
			type: String,
			default: "Label"
		},
		item_text: {
			type: String,
			default: "value"
		},
		item_value: {
			type: String,
			default: "id"
		},
		searchable: {
			type: Boolean,
			default: false
		},
		filterable: {
			type: Boolean,
			default: false
		},
		unique: {
			type: Boolean,
			default: false
		},
		customClass: {
			type: String,
			default: "label-fixed"
		},
		counter: {
			type: Number,
			default: 255
		},
		disabled: {
			type: Boolean,
			default: false
		},
		multiple: {
			type: Boolean,
			default: false
		},
		chips: {
			type: Boolean,
			default: false
		},
		deletable_chips: {
			type: Boolean,
			default: false
		},
		small_chips: {
			type: Boolean,
			default: false
		},
		dense: {
			type: Boolean,
			default: false
		},
		persistent_hint: {
			type: Boolean,
			default: false
		},
		required: {
			type: Boolean,
			default: false
		},
		clearable: {
			type: Boolean,
			default: true
		},
		auto_select_first: {
			type: Boolean,
			default: false
		},
		search_input_sync: {
			default: null
		},
		colapse_selection: {
			type: Boolean,
			default: false
		},
		use_display_name: {
			type: Boolean,
			default: true
		},
		predicates: {
			type: Object,
			default: function() {
				return {};
			}
		},
		campaignPMP: {
			type: Boolean,
			default: false
		},
		messageRequire: {
			type: String,
			default: ""
		},
		readonly:{
			type:Boolean,
			default: false
		}
	},
	components: {
		CardAutocomplete,
		TermList,
		TermListUnique
	},
	data: () => ({}),
	async created() {},
	async mounted() {},
	computed: {		
		modelData: {
			set(val: any) {
				this.$emit("input", val);
			},
			get() {
				return this.appLists;
			},
		},
	},
	methods: {
		removeHandler(value: any) {
			this.$emit("remove-item", value);
		},
		clearHandler() {
			this.$emit("clear");
		},

		syncHandler(data: any) {
			this.$emit("sync", data);
		},

		focusHandler() {
			this.$emit("focus");
		}
	},

	watch: {
		// "targeting_key_data.by_attribute"(val) {
		// 	console.log("watch::by_attribute", { val });
		// }
	}
});


import Vue from "vue";
// @ts-ignore
import { SegmentSearchOption } from "@/interfaces/segment";
// @ts-ignore
import { isEmpty, isNull, isUndefined, last } from "lodash";
// @ts-ignore
import { processSegmentSelection } from "@/utils/resolveObjectArray";

import {
	filterCurrentSelection,
	filterNoInSelection
	// @ts-ignore
} from "@/utils/filter-global";
// @ts-ignore
import { mapActions } from "vuex";

export default Vue.extend({
    name:"SegmentSearch",
    props:{
        SegmentSearch: {
			type: Object,
            required: true,
			default: function () {
				return {};
			},
		},
        searchValue: {
            type: String,
            default:"",
        },
        line: {
			type: Object,
			default: function () {
				return {};
			},
		},
		user: {
			type: Object,
			default: function () {
				return {};
			},
		},
		predicates: {
			type: Object,
			default: function () {
				return {};
			},
		},
        readonly:{
			type:Boolean,
			default: false
		}
    },
    components:{},
    data: () => ({
        tab: "user",
        selected: [],
        selection: [] as Array<string>,
        items: [] as Array<any>,
        segmentSearch:{
			search: "",
			options: {
				page: 1,
				limit: 20,
				data_provider_key: undefined
			} as SegmentSearchOption,
		},
        modes:{
            modeLoading:false,
            modeAll:false
        } 
    }),
    created(){},
    mounted(){
        this.$nextTick(async () => {
            await this.setAttributes();
            await this.setDataItems(this.getSegments);
            await this.checkItemSelected();
            this.verifyAll();
        });
    },
    computed:{

        getSegments(){
            return this.SegmentSearch.segments;
        },

        getDataProvider(){
            return this.SegmentSearch.key;
        }

    },
    methods:{

        ...mapActions("segment", [
			"getSegmentSearch"
		]),

        isCategoryItem(value: any) {
			return value == "category";
		},

        getPreparedItem(item: any){
            return {
                id: item.id,
                name: item.name,
                key: item.key,
                external_id: item?.external_id,
                segments_count: item?.segments_count,
                verb_usage_id: isUndefined(item.verb_usage?.id) ? undefined : item.verb_usage?.id,
                verb_usage_name: isUndefined(item.verb_usage) ? undefined : item.verb_usage,
                is_category: this.isCategoryItem(item.object_type),
                segment_nam: item.segment_nam,
                data_provider_key: this.getDataProvider,
                explandible: undefined,
                has_categories: false,
                children: undefined,
                segment_tree: undefined,
                sub_categories_count: 0,
            }
        },

        getPreparedItemValue(item: any, key: any){
            return {
                tab: this.tab,
				key: key,
				value: item.key,
				verb_usage: item.verb_usage_id,
				segment_name: item.name,
				verb_usage_name: item.verb_usage_name,
				is_category: item.is_category,
            }
        },

        async actionLoadMore(){
            this.modes.modeLoading = true;
            this.segmentSearch.options.page += 1;
            const response = await this.getSegmentSearch(this.segmentSearch);
            response.forEach((element) => {
                this.setDataItems(element.segments);
            });
            await this.checkItemSelected();
            this.modes.modeLoading = false;
        },

        async setAttributes(){
            this.segmentSearch.search = this.searchValue;
            this.segmentSearch.options.data_provider_key = this.SegmentSearch.key;
        },

        async setDataItems(values: Array<any>){
            if(isEmpty(values)) return;
            values.forEach((element) => {
                const item = this.getPreparedItem(element);
                this.items.push(item);
            });
        },
        
        async updateWatchByKey(
			key: String,
			val: Array<any>,
			old: Array<any>,
			is_unique: Boolean = false
		) {
            
			let item: Array<any> = [];
			const current: Array<any> = val || [];
			const older: Array<any> = old || [];

			if (current.length > older.length) {
				item = current.filter(function (o: any) {
					return !(older.filter((e) => e.key === o.key).length > 0);
				});
			}

			if (current.length <= older.length) {
				item = older.filter(function (o: any) {
					return !(current.filter((e) => e.key === o.key).length > 0);
				});
			}

			const element = !isEmpty(item) ? last(item) : null;

			if (!element || element?.has_categories || element?.is_category) return;

			const params = {
				tab: this.tab,
				key: key,
				value: element.key,
				verb_usage: element.verb,
				segment_name: element.segment_name,
				verb_usage_name: element.verb_usage_name,
				is_category: element.is_category,
			};

			this.user.segment.value = current;

			const fire = current.length > older.length ? "add-item" : "remove-item";

			this.$emit(fire, { unique: is_unique, params: params });
            await this.updateSegmentSelectedCheck();
            this.verifyAll();
		},

        async checkItemSelected(){
            const data = this.user.segment.targeting_terms.map((i) => i.value);
            await this.updateWatchBySegment(data);
        },

        async updateWatchBySegment(val: Array<string>){
            if(isEmpty(this.items)) return;
            val.forEach((element) => {
                const d = this.items.find((i) => i.key == element);
                if(!isUndefined(d)){
                    if(!this.selected.some((i) => i.key == d.key)){
                        this.selected.push(d);
                        this.selection.push(d.key);
                    }
                }
            });
        },

        async updateSegmentSelectedCheck(val: Array<string>){
            if(isEmpty(this.selection)) {
                this.selected = [];
            } else {
                this.selected = [];
                this.selection.forEach((element) => {
                    const d = this.items.find((i) => i.key == element);
                    this.selected.push(d);
                });
            }
        },

        async updateSegmentSelectedCheckTargeting(){
            if(isEmpty(this.user.segment.targeting_terms)) {
                this.selection = [];
            } else {
                this.selection = [];
                this.user.segment.targeting_terms.forEach((element) => {
                    this.selection.push(element.value);
                });
            }
        },

        async selectedAllItems(val: any){
            if(val){
                if(isEmpty(this.items)) return;
                this.items.forEach((element) => {
                    if(!this.selection.some((i) => i == element.key)){
                        const params = this.getPreparedItemValue(element, "segment");
                        this.$emit("add-item", { unique: false, params: params });
                        this.selected.push(element);
                        this.selection.push(element.key);
                    }
                });
            }else{
                this.items.forEach((element) => {
                    const params = this.getPreparedItemValue(element, "segment");
                    this.$emit("remove-item", { unique: false, params: params });
                });
                this.selected = [];
                this.selection = [];
            }
        },

        verifyAll(){
            if(this.user.segment.targeting_terms.length == this.items.length){
                this.modes.modeAll = true;
            }else{
                this.modes.modeAll = false;
            }
        },

        isCheckAll(){
            return this.modes.modeAll;
        }

    },
    watch: {
		async selection(val: Array<string>, old: Array<string>) {
            //if(!this.isCheckAll()){
                const current: Array<string> = val;
                const older: Array<string> = old;
                const treeview: Array<any> = this.items;

                if (isEmpty(treeview)) return;

                const { newValue, oldValue } = await processSegmentSelection(
                    current,
                    older,
                    treeview
                );

                if (isEmpty(newValue) && isEmpty(oldValue)) return;
                
                await this.updateWatchByKey("segment", newValue, oldValue, false);
            //}
		},
		async "user.segment.targeting_terms"(newValue, oldValue) {
			await this.updateSegmentSelectedCheckTargeting();
            await this.updateSegmentSelectedCheck();
		},
	},

});



import Vue from "vue";
import TabItem from "./tab_items/TabItem.vue";
import { isEmpty, isUndefined } from "lodash";
import { mapActions } from "vuex";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";

export default Vue.extend({
	name: "Exchange",
	props: {
		exchange: {
			type: Object,
			default: function() {}
		},
		data_variables: {
			type: Object,
			default: function() {
				return {};
			}
		},
		predicates: {
			type: Object,
			default: function() {
				return {};
			}
		},
		is_edit: {
			type: Boolean,
			default: false
		},
		readonly:{
			type:Boolean,
			default: false
		}
	},
	components: {
		TabItem
	},
	data: () => ({
		tab: "exchange",
		inventorySourceTerm: null,
		auctionTypeSourceTerm: null
	}),
	async created() {},
	async mounted() {
		await this.requiredResources();
	},
	computed: {},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		async requiredResources() {
			if (this.is_edit) {
				const keys = Object.keys(this.exchange);

				keys.forEach(async key => {
					if (
						!isUndefined(this.exchange[key]["targeting_terms"]) &&
						!isEmpty(this.exchange[key]["targeting_terms"])
					) {
						await this.getComboData(key);
					}
				});
			}
		},

		async getComboData(key: string) {
			try {
				let params = {};

				if (isEmpty(this.data_variables[key])) {
					switch (key) {
						case "inventory_source":
							params = {
								key: "key",
								value: "name"
							};

							break;

						case "auction_type":
							params = {
								key: "extra",
								value: "description"
							};

							break;
					}

					this.setLoadingData(TypeLoading.loading)

					this.$emit("update-data-var", {
						tab: this.tab,
						key: key,
						params: params,
						data: await this.dispatchTargetingKey(key, params)
					});

					await this.setLoadingData();
				}
			} catch (error) {
				console.error("getComboData", { key, error });
				await this.setLoadingData();
			}
		},
		// async dispatchSearchByTerm(params: any) {
		// 	return this.$store.dispatch("targeting/getSearchByTerm", params);
		// },
		async dispatchTargetingKey(key: String, object?: any) {
			return this.$store.dispatch("targeting/getTargetingKey", {
				key: key,
				object: object
			});
		},
		async getAppNameByAtribute(params: {
			term: String;
			by_attribute: String;
		}) {
			return this.$store.dispatch(
				"targeting/getAppNameByAtribute",
				params
			);
		},
		async getSitesByAtribute(params: {
			term: String;
			by_attribute: String;
		}) {
			return this.$store.dispatch("targeting/getSitesByAtribute", params);
		},

		clearHandler(key: any) {
			this.$emit("clear-app-site", { tab: this.tab, key: key });
		},

		syncData(key: String, term: String) {
			switch (key) {
				case "inventory_source":
					this.inventorySourceTerm = term;

					break;

				case "auction_type":
					this.auctionTypeSourceTerm = term;

					break;
			}
		},

		removeHandler(key: any, value: any, is_unique: Boolean = false) {
			this.$emit(is_unique ? "remove-item-unique" : "remove-item", {
				tab: this.tab,
				key: key,
				value: value,
				is_unique: is_unique
			});
		},

		async updateWatchByKey(
			key: String,
			val: Array<any>,
			old: Array<any>,
			is_unique: Boolean = false
		) {
			if (val.length > old.length) {
				const item = val.filter(function(o: any) {
					return !old.includes(o);
				});
				this.$emit(is_unique ? "add-item-unique" : "add-item", {
					tab: this.tab,
					key: key,
					value: item[0]
				});
			}
			if (val.length < old.length) {
				const item = old.filter(function(o: any) {
					return !val.includes(o);
				});
				this.$emit(is_unique ? "remove-item-unique" : "remove-item", {
					tab: this.tab,
					key: key,
					value: item[0]
				});
			}
		}
	},

	watch: {
		async "exchange.inventory_source.value"(
			val: Array<any>,
			old: Array<any>
		) {
			await this.updateWatchByKey("inventory_source", val, old, true);
		},
		async "exchange.auction_type.value"(val: Array<any>, old: Array<any>) {
			await this.updateWatchByKey("auction_type", val, old, true);
		}

		// inventorySourceTerm: debounce(async function (
		// 	val: String,
		// 	old: String
		// ) {
		// 	if (val.length < 3) return;
		// 	this.setLoadingData(TypeLoading.loading)
		// 	this.$emit("update-data-var", {
		// 		tab: this.tab,
		// 		key: "inventory_source",
		// 		data: await this.dispatchSearchByTerm({
		// 			term: val,
		// 			key: "inventory_source",
		// 			by_attribute: this.exchange.inventory_source.by_attribute,
		// 			object: {
		// 				key: "internal_id",
		// 				value: "name",
		// 			},
		// 		}),
		// 	});

		// 	await this.setLoadingData();
		// },
		// 500),
	}
});
